import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "../../assets/i18n/en.json";
import translationES from "../../assets/i18n/es.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es",

    //debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
