import React, { Component } from "react";
import { Growl } from "primereact/growl";
import { withTranslation } from "react-i18next";

/* Ejemplo de uso
 *  Constructor : this.growlPersonalizado = React.createRef();
 *
 *  Html : <GrowlPersonalizado ref={this.growlPersonalizado} />
 *
 *  Metodo: this.growlPersonalizado.current.mostrarMensajeCorrecto("titulo","cuerpo");
 */
class GrowlPersonalizado extends Component {
  //let growl = useRef(null);

  mostrarMensajeCorrecto = (cuerpo) => {
    this.growl.show({
      severity: "success",
      summary: this.props.t("MENSAJES.TITULO.CORRECTO"),
      detail: cuerpo,
    });
  };
  mostrarMensajeInfo = (cuerpo) => {
    this.growl.show({
      severity: "info",
      summary: this.props.t("MENSAJES.TITULO.INFO"),
      detail: cuerpo,
    });
  };

  mostrarMensajeAdvertencia = (cuerpo) => {
    this.growl.show({
      severity: "warn",
      summary: this.props.t("MENSAJES.TITULO.ADVERTENCIA"),
      detail: cuerpo,
    });
  };

  mostrarMensajeError = (cuerpo) => {
    this.growl.show({
      severity: "error",
      summary: this.props.t("MENSAJES.TITULO.ERROR"),
      detail: cuerpo,
    });
  };

  mostrarMensajePersonalizado = (cuerpo) => {
    this.growl.show(cuerpo);
  };

  render() {
    return <Growl ref={(el) => (this.growl = el)} />;
  }
}

export default withTranslation(undefined, { withRef: true })(
  GrowlPersonalizado
);
