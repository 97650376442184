import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import CalendarioPersonalizado from "../../../components/calendarioPersonalizado/Calendario-personalizado";
import ToolbarPersonalizado from "../../../components/toolbarPersonalizado/Toolbar-personalizado";
import { InputText } from "primereact/inputtext";
import {
  EXPRESIONREGULAREMAIL,
  EXPRESIONREGULARURL,
} from "../../../utils/constantes/constantes";
import { ColorPicker } from "primereact/colorpicker";
import { Editor } from "primereact/editor";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { Fieldset } from "primereact/fieldset";
import EventoPrevisualizacion from "../eventoPrevisualizacion/Evento-previsualizacion";
import FileUploadPersonalizado from "../../../components/fileUploadPersonalizado/FileUpload-personalizado";
import ImagenService from "../../../services/imagenService/imagen-service";
import GrowlPersonalizado from "../../../components/growlPersonalizado/Growl-pesonalizado";
import EventoService from "../../../services/eventoService/evento-service";
import {
  recuperarValorLocalStorage,
  guardarEnLocalStorage,
  compararFechas,
  eliminarClaveLocalStorage,
} from "../../../utils/funciones/funciones";
import { InputNumber } from "primereact/inputnumber";

class EventoNuevo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titulo: recuperarValorLocalStorage("evento.titulo") || "",
      artista: recuperarValorLocalStorage("evento.artista") || "",
      email: recuperarValorLocalStorage("evento.email") || "",
      editor: recuperarValorLocalStorage("evento.editor") || "",
      tituloProducto: recuperarValorLocalStorage("evento.tituloProducto") || "",
      categoriaProducto:
        recuperarValorLocalStorage("evento.categoriaProducto") || "",
      imagenFondo: "",
      nombreOrganizador:
        recuperarValorLocalStorage("evento.nombreOrganizador") || "",
      urlOrganizador: recuperarValorLocalStorage("evento.urlOrganizador") || "",
      logoOrganizador: "",
      fechaInicioEvento:
        recuperarValorLocalStorage("evento.fechaInicioEvento") === null
          ? ""
          : new Date(recuperarValorLocalStorage("evento.fechaInicioEvento")),
      fechaInicioRegistro:
        recuperarValorLocalStorage("evento.fechaInicioRegistro") === null
          ? ""
          : new Date(recuperarValorLocalStorage("evento.fechaInicioRegistro")),
      fechaLimiteRegistro:
        recuperarValorLocalStorage("evento.fechaLimiteRegistro") === null
          ? ""
          : new Date(recuperarValorLocalStorage("evento.fechaLimiteRegistro")),
      urlFacebook: recuperarValorLocalStorage("evento.urlFacebook") || "",
      urlInstagram: recuperarValorLocalStorage("evento.urlInstagram") || "",
      urlEvento: recuperarValorLocalStorage("evento.urlEvento") || "",
      urlSalida: recuperarValorLocalStorage("evento.urlSalida") || "",
      colorLetra: recuperarValorLocalStorage("evento.colorLetra") || "000000",
      terminosYCondiciones:
        recuperarValorLocalStorage("evento.terminosYCondiciones") || "",
      mostrarPrevisualizacionEvento: false,
      precio: parseFloat(recuperarValorLocalStorage("evento.precio")) || 0,
      camposRegistroEventoObligatorios: [],
      evento: {},
    };
    this.growlPersonalizado = React.createRef();
    this.t = props.t;
    this.guardarImagenesAlS3 = this.guardarImagenesAlS3.bind(this);
  }

  anadirPrioridadACamposUsuarioObligatorios() {
    let resultado = [];
    if (
      this.state.camposRegistroEventoObligatorios.length > 0 &&
      this.state.camposRegistroEventoObligatorios != null
    ) {
      for (
        let x = 0;
        x < this.state.camposRegistroEventoObligatorios.length;
        x++
      ) {
        resultado.push(
          this.devolverCamposUsuarioObligatoriosConPrioridad(
            this.state.camposRegistroEventoObligatorios[x]
          )
        );
      }
    }
    return resultado;
  }

  devolverCamposUsuarioObligatoriosConPrioridad(nombreCampo) {
    let objeto;
    switch (nombreCampo) {
      case "Nombre":
        objeto = { value: "Nombre", order: 1 };
        break;
      case "Apellidos":
        objeto = { value: "Apellidos", order: 2 };
        break;
      case "Edad":
        objeto = { value: "Edad", order: 3 };
        break;
      case "Correo":
        objeto = { value: "Correo", order: 5 };
        break;
      case "Teléfono":
        objeto = { value: "Teléfono", order: 6 };
        break;
      case "Sexo":
        objeto = { value: "Sexo", order: 4 };
        break;

      default:
        objeto = null;

        break;
    }
    return objeto;
  }

  prepararEvento() {
    let evento = {
      titulo: this.state.titulo,
      artista: this.state.artista,
      email: this.state.email,
      editor: this.state.editor,
      organizador: {
        nombre: this.state.nombreOrganizador,
        url: this.state.urlOrganizador,
        logo: this.state.logoOrganizador,
      },
      fechaInicioEvento: this.state.fechaInicioEvento,
      fechaInicioRegistro: this.state.fechaInicioRegistro,
      fechaLimiteRegistro: this.state.fechaLimiteRegistro,
      urlEvento: this.state.urlEvento,
      urlSalida: this.state.urlSalida,
      imagenFondo: this.state.imagenFondo,
      categoriaProducto: this.state.categoriaProducto,
      tituloProducto: this.state.tituloProducto,
      colorLetra: "#" + this.state.colorLetra,
      terminosYCondiciones: this.state.terminosYCondiciones,
      camposRegistroEventoObligatorios: this.anadirPrioridadACamposUsuarioObligatorios(),
      precio: this.state.precio,
      cuerpoMensaje:
        "Por la compra del " +
        this.state.categoriaProducto +
        " " +
        this.state.tituloProducto +
        " te invitamos a disfrutar de un encuentro con " +
        this.state.artista +
        " de " +
        this.state.editor,
      paginas: [],
    };

    new RegExp(EXPRESIONREGULARURL).test(this.state.urlFacebook) &&
      evento.paginas.push({
        tipo: "Facebook",
        url: this.state.urlFacebook,
      });

    new RegExp(EXPRESIONREGULARURL).test(this.state.urlInstagram) &&
      evento.paginas.push({
        tipo: "Instagram",
        url: this.state.urlInstagram,
      });

    this.setState({ evento: evento });
  }

  previsualizarEvento = () => {
    this.prepararEvento();
    this.setState({ mostrarPrevisualizacionEvento: true });
  };

  limpiarStateYLocalStorage() {
    let objetosState = Object.keys(this.state);
    for (let x = 0; x < objetosState.length; x++) {
      if (objetosState[x] === "evento") {
        this.setState({ [objetosState[x]]: {} });
      } else if (objetosState[x] === "mostrarPrevisualizacionEvento") {
        this.setState({ [objetosState[x]]: false });
      } else if (objetosState[x] === "precio") {
        this.setState({ [objetosState[x]]: 0 });
      } else if (objetosState[x] === "camposRegistroEventoObligatorios") {
        this.setState({ [objetosState[x]]: [] });
      } else {
        this.setState({ [objetosState[x]]: "" });
      }
      eliminarClaveLocalStorage("evento." + objetosState[x]);
    }
  }

  guardarImagenesAlS3YEvento = (e) => {
    e.preventDefault();
    let camposComprobados = this.comprobarImagenesFechasYTerminosYCondiciones();
    if (camposComprobados) {
      let imagenesGuardadas = this.guardarImagenesAlS3();
      imagenesGuardadas.then((resultado) => {
        if (resultado === true) {
          this.prepararEvento();
          EventoService.crearEvento(this.state.evento)
            .then((success) => {
              this.limpiarStateYLocalStorage();
              this.growlPersonalizado.current.mostrarMensajeCorrecto(
                this.t("MENSAJES.EVENTO_CREADO_OK")
              );
            })
            .catch((error) => {
              this.growlPersonalizado.current.mostrarMensajeError(
                this.t("MENSAJES.EVENTO_CREADO_ERROR")
              );
            });
        } else {
          this.growlPersonalizado.current.mostrarMensajeError(
            this.t("MENSAJES.IMAGEN_SUBIR_S3_ERROR")
          );
        }
      });
    }
  };

  /*  async contrasteColores(elemento) {
    let imagen = await transformarBase64AImagen(elemento);
    let rgb = await getAverageRGB(imagen);
    this.setState({ imagenFondo: elemento });
    let rgbInvertido = invertRGB(rgb);

    this.setState({
      colorLetra:
        "rgb(" +
        rgbInvertido.r +
        "," +
        rgbInvertido.g +
        "," +
        rgbInvertido.b +
        ")",
    });
  } */

  comprobarImagenesFechasYTerminosYCondiciones = () => {
    let resultado = true;
    if (this.state.logoOrganizador === "") {
      this.growlPersonalizado.current.mostrarMensajeAdvertencia(
        this.props.t("MENSAJES.ORGANIZADOR_LOGO_VALIDADO_ERROR")
      );
      resultado = false;
    }

    if (this.state.terminosYCondiciones === "") {
      this.growlPersonalizado.current.mostrarMensajeAdvertencia(
        this.props.t("MENSAJES.TERMINOSYCONDICIONES_VALIDADO_ERROR")
      );
      resultado = false;
    }

    if (this.state.imagenFondo === "") {
      this.growlPersonalizado.current.mostrarMensajeAdvertencia(
        this.props.t("MENSAJES.IMAGEN_FONDO_VALIDADO_ERROR")
      );
      resultado = false;
    }

    if (
      compararFechas(
        this.state.fechaInicioRegistro,
        this.state.fechaLimiteRegistro
      )
    ) {
      this.growlPersonalizado.current.mostrarMensajeAdvertencia(
        this.props.t(
          "MENSAJES.FECHA_INICIO_REGISTRO_ES_MAYOR_FECHA_LIMITE_REGISTRO"
        )
      );
      resultado = false;
    }

    if (
      compararFechas(
        this.state.fechaInicioRegistro,
        this.state.fechaInicioEvento
      )
    ) {
      this.growlPersonalizado.current.mostrarMensajeAdvertencia(
        this.props.t(
          "MENSAJES.FECHA_INICIO_REGISTRO_ES_MAYOR_FECHA_INICIO_EVENTO"
        )
      );
      resultado = false;
    }

    if (
      compararFechas(
        this.state.fechaLimiteRegistro,
        this.state.fechaInicioEvento
      )
    ) {
      this.growlPersonalizado.current.mostrarMensajeAdvertencia(
        this.props.t(
          "MENSAJES.FECHA_LIMITE_REGISTRO_ES_MAYOR_FECHA_INICIO_EVENTO"
        )
      );
      resultado = false;
    }

    return resultado;
  };

  setStateYLocalStorage(e) {
    const nombre = e.target.name;
    this.setState({ [nombre]: e.target.value });
    guardarEnLocalStorage("evento." + nombre, e.target.value);
  }

  async guardarImagenesAlS3() {
    let resultado = true;
    let imagenesBase64 = [this.state.imagenFondo, this.state.logoOrganizador];
    for (var x = 0; x < imagenesBase64.length; x++) {
      let imageBase64 = imagenesBase64[x];
      let nombre = x === 0 ? "fondo" : "logo";
      try {
        const response = await ImagenService.guardarImagen({
          base64: imageBase64,
          nombre: nombre,
        });
        if (nombre === "fondo")
          this.setState({ imagenFondo: response.data.url });
        if (nombre === "logo")
          this.setState({ logoOrganizador: response.data.url });
      } catch (error) {
        resultado = false;
      }
    }
    return resultado;
  }

  render() {
    const { t } = this.props;
    const camposRegistroEvento = [
      { label: "Nombre", value: "Nombre", order: 1 },
      { label: "Apellidos", value: "Apellidos", order: 2 },
      { label: "Correo", value: "Correo", order: 3 },
      { label: "Edad", value: "Edad", order: 4 },
      { label: "Teléfono", value: "Teléfono", order: 5 },
    ];
    //console.log(this.state);

    return (
      <>
        <ToolbarPersonalizado
          titulo={t("TITULO.CREAR_EVENTO")}
          mostrarBotonRetrocederPagina={true}
        />

        <form name="crearEvento" onSubmit={this.guardarImagenesAlS3YEvento}>
          <GrowlPersonalizado ref={this.growlPersonalizado} />
          <div className="container">
            <div className="row mt-4">
              <div className="col-12 col-sm-4 col-md-6 col-lg-4 pb-4">
                <span className="p-float-label">
                  <InputText
                    id="titulo_evento"
                    name="titulo"
                    className="form-control"
                    title={t("EVENTO.TITULO")}
                    type="text"
                    onChange={(e) => this.setStateYLocalStorage(e)}
                    value={this.state.titulo}
                    required={true}
                  ></InputText>
                  <label htmlFor="titulo_evento">{t("EVENTO.TITULO")}</label>
                </span>
              </div>
              <div className="col-12 col-sm-4 col-md-6 col-lg-4 pb-4">
                <span className="p-float-label">
                  <InputText
                    id="artista"
                    name="artista"
                    className="form-control"
                    title={t("GENERICO.ARTISTA")}
                    type="text"
                    onChange={(e) => this.setStateYLocalStorage(e)}
                    value={this.state.artista}
                    required={true}
                  ></InputText>
                  <label htmlFor="artista">{t("GENERICO.ARTISTA")}</label>
                </span>
              </div>

              <div className="col-12 col-sm-4 col-md-6 col-lg-4 pb-4 ">
                <span className="p-float-label">
                  <InputText
                    id="email"
                    className="form-control"
                    title={t("GENERICO.EMAIL")}
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={(e) => this.setStateYLocalStorage(e)}
                    pattern={EXPRESIONREGULAREMAIL}
                    autoComplete="off"
                    required={true}
                  ></InputText>
                  <label htmlFor="email">{t("GENERICO.EMAIL")}</label>
                </span>
              </div>
              <div className="col-12 col-sm-4 col-md-6 col-lg-4 pb-4 ">
                <span className="p-float-label">
                  <InputText
                    id="editor"
                    className="form-control"
                    title={t("GENERICO.EDITOR")}
                    type="text"
                    name="editor"
                    value={this.state.editor}
                    onChange={(e) => this.setStateYLocalStorage(e)}
                    autoComplete="off"
                    required={true}
                  ></InputText>
                  <label htmlFor="editor">{t("GENERICO.EDITOR")}</label>
                </span>
              </div>
              <div className="col-12 col-sm-4 col-md-6 col-lg-4 pb-4 ">
                <span className="p-float-label">
                  <InputText
                    id="titulo_producto"
                    className="form-control"
                    title={t("PRODUCTO.TITULO")}
                    type="text"
                    name="tituloProducto"
                    value={this.state.tituloProducto}
                    onChange={(e) => this.setStateYLocalStorage(e)}
                    autoComplete="off"
                    required={true}
                  ></InputText>
                  <label htmlFor="titulo_producto">
                    {t("PRODUCTO.TITULO")}
                  </label>
                </span>
              </div>
              <div className="col-12 col-sm-4 col-md-6 col-lg-4 pb-4 ">
                <span className="p-float-label">
                  <InputText
                    id="categoria_producto"
                    className="form-control"
                    title={t("PRODUCTO.CATEGORIA")}
                    type="text"
                    name="categoriaProducto"
                    value={this.state.categoriaProducto}
                    onChange={(e) => this.setStateYLocalStorage(e)}
                    autoComplete="off"
                    required={true}
                  ></InputText>
                  <label htmlFor="categoria_producto">
                    {t("PRODUCTO.CATEGORIA")}
                  </label>
                </span>
              </div>

              <div className="col-12 col-sm-4 col-md-6 col-lg-4 pb-4 ">
                <FileUploadPersonalizado
                  nombre="imagenFondo"
                  nombreBoton="Imagen de fondo"
                  statePrevisualizacion={(e) =>
                    this.setState({ imagenFondo: e })
                  }
                />
              </div>
              <div className="col-12 col-sm-4 col-md-6 col-lg-4 pb-4 ">
                <span className="p-float-label">
                  <InputNumber
                    name="precio"
                    value={this.state.precio}
                    onChange={(e) => this.setStateYLocalStorage(e)}
                    showButtons
                    mode="currency"
                    currency="EUR"
                    step={0.25}
                  />
                  <label htmlFor="categoria_producto">
                    {t("GENERICO.PRECIO")}
                  </label>
                </span>
              </div>
            </div>

            <Fieldset legend="Organizador">
              <div className="row mb-4 mb-md-3 mb-lg-3">
                <div className="col-12 col-sm-4 col-md-6 col-lg-4 mt-4 mt-md-3 mt-lg-3">
                  <span className="p-float-label">
                    <InputText
                      id="nombre_organizador"
                      className="form-control"
                      title={t("GENERICO.NOMBRE")}
                      type="text"
                      name="nombreOrganizador"
                      value={this.state.nombreOrganizador}
                      onChange={(e) => this.setStateYLocalStorage(e)}
                      autoComplete="off"
                      required={true}
                    ></InputText>
                    <label htmlFor="nombre_organizador">
                      {t("GENERICO.NOMBRE")}
                    </label>
                  </span>
                </div>
                <div className="col-12 col-sm-4 col-md-6 col-lg-4 mt-4 mt-md-3 mt-lg-3">
                  <span className="p-float-label">
                    <InputText
                      id="url_organizador"
                      className="form-control"
                      title={t("GENERICO.URL_SITIO_WEB")}
                      type="text"
                      name="urlOrganizador"
                      value={this.state.urlOrganizador}
                      onChange={(e) => this.setStateYLocalStorage(e)}
                      autoComplete="off"
                      pattern={EXPRESIONREGULARURL}
                      required={true}
                    ></InputText>
                    <label htmlFor="url_organizador">
                      {t("GENERICO.URL_SITIO_WEB")}
                    </label>
                  </span>
                </div>
                <div className="col-12 col-sm-4 col-md-6 col-lg-4 mt-4 mt-md-3 mt-lg-3 ">
                  <FileUploadPersonalizado
                    nombreBoton={t("GENERICO.LOGO")}
                    statePrevisualizacion={(e) =>
                      this.setState({ logoOrganizador: e })
                    }
                  />
                </div>
              </div>
            </Fieldset>
            <Fieldset legend="Urls">
              <div className="row mb-4 mb-md-3 mb-lg-3">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4  mt-4 mt-md-3 mt-lg-3 ">
                  <span className="p-float-label">
                    <InputText
                      id="url_salida"
                      className="form-control"
                      title={t("EVENTO.URL_SALIDA")}
                      type="text"
                      name="urlSalida"
                      value={this.state.urlSalida}
                      onChange={(e) => this.setStateYLocalStorage(e)}
                      autoComplete="off"
                      required={true}
                      pattern={EXPRESIONREGULARURL}
                    ></InputText>
                    <label htmlFor="url_salida">{t("EVENTO.URL_SALIDA")}</label>
                  </span>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-4 mt-md-3 mt-lg-3 ">
                  <span className="p-float-label">
                    <InputText
                      id="url_evento"
                      className="form-control"
                      title={t("EVENTO.URL")}
                      type="text"
                      name="urlEvento"
                      value={this.state.urlEvento}
                      onChange={(e) => this.setStateYLocalStorage(e)}
                      autoComplete="off"
                      required={true}
                      pattern={EXPRESIONREGULARURL}
                    ></InputText>
                    <label htmlFor="url_evento">{t("EVENTO.URL")}</label>
                  </span>
                </div>
              </div>
            </Fieldset>
            <Fieldset legend="Redes sociales">
              <div className="row mb-4 mb-md-3 mb-lg-3">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4  mt-4 mt-md-3 mt-lg-3 ">
                  <span className="p-float-label">
                    <InputText
                      id="url_instagram"
                      className="form-control"
                      title={t("REDES_SOCIALES.INSTAGRAM")}
                      type="text"
                      name="urlInstagram"
                      value={this.state.urlInstagram}
                      onChange={(e) => this.setStateYLocalStorage(e)}
                      autoComplete="off"
                      pattern={EXPRESIONREGULARURL}
                    ></InputText>
                    <label htmlFor="url_instagram">
                      {t("REDES_SOCIALES.INSTAGRAM")}
                    </label>
                  </span>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-4 mt-md-3 mt-lg-3 ">
                  <span className="p-float-label">
                    <InputText
                      id="url_facebook"
                      className="form-control"
                      title={t("REDES_SOCIALES.FACEBOOK")}
                      type="text"
                      name="urlFacebook"
                      value={this.state.urlFacebook}
                      onChange={(e) => this.setStateYLocalStorage(e)}
                      autoComplete="off"
                      pattern={EXPRESIONREGULARURL}
                    ></InputText>
                    <label htmlFor="url_facebook">
                      {t("REDES_SOCIALES.FACEBOOK")}
                    </label>
                  </span>
                </div>
              </div>
            </Fieldset>
            <Fieldset legend="Fechas">
              <div className="row mb-4 mb-md-3 mb-lg-3">
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 mt-4 mt-md-3 mt-lg-3 ">
                  <span className="p-float-label p-fluid">
                    <CalendarioPersonalizado
                      id="fecha_inicio_registro"
                      className="form-control"
                      title={t("GENERICO.FECHA_INICIO_REGISTRO")}
                      type="text"
                      name="fechaInicioRegistro"
                      value={this.state.fechaInicioRegistro}
                      cambioValor={(e) => this.setStateYLocalStorage(e)}
                      autoComplete="off"
                      required={true}
                    ></CalendarioPersonalizado>
                    <label htmlFor="fecha_inicio_registro">
                      {t("GENERICO.FECHA_INICIO_REGISTRO")}
                    </label>
                  </span>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 mt-4 mt-md-3 mt-lg-3 ">
                  <span className="p-float-label p-fluid">
                    <CalendarioPersonalizado
                      id="fecha_limite_registro"
                      className="form-control"
                      title={t("GENERICO.FECHA_LIMITE_REGISTRO")}
                      type="text"
                      name="fechaLimiteRegistro"
                      value={this.state.fechaLimiteRegistro}
                      cambioValor={(e) => this.setStateYLocalStorage(e)}
                      autoComplete="off"
                      required={true}
                    ></CalendarioPersonalizado>
                    <label htmlFor="fecha_limite_registro">
                      {t("GENERICO.FECHA_LIMITE_REGISTRO")}
                    </label>
                  </span>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 mt-4 mt-md-3 mt-lg-3 ">
                  <span className="p-float-label p-fluid">
                    <CalendarioPersonalizado
                      id="fecha_inicio"
                      className="form-control"
                      title={t("EVENTO.FECHA_INICIO")}
                      type="text"
                      value={this.state.fechaInicioEvento}
                      name="fechaInicioEvento"
                      cambioValor={(e) => this.setStateYLocalStorage(e)}
                      autoComplete="off"
                      required={true}
                    ></CalendarioPersonalizado>
                    <label htmlFor="fecha_inicio">
                      {t("EVENTO.FECHA_INICIO")}
                    </label>
                  </span>
                </div>
              </div>
            </Fieldset>
            <Fieldset legend="Campos registro usuario">
              <div className="row">
                <div className="col-12 col-sm-4 col-md-6 col-lg-4">
                  <MultiSelect
                    value={this.state.camposRegistroEventoObligatorios}
                    className="form-control"
                    options={camposRegistroEvento}
                    onChange={(e) => {
                      this.setState({
                        camposRegistroEventoObligatorios: e.value,
                      });
                    }}
                    style={{ minWidth: "100%", padding: "0" }}
                    filter={true}
                    filterPlaceholder="Buscar"
                    placeholder="Seleccionar"
                  />
                </div>
                <div className="col-12 col-sm-4 col-md-6 col-lg-4 pb-4 ">
                  {this.state.camposRegistroEventoObligatorios.length > 3 &&
                    this.state.camposRegistroEventoObligatorios.map(
                      (campos) => <li key={campos}>{campos}</li>
                    )}
                </div>
              </div>
            </Fieldset>

            <Fieldset
              className="mt-3"
              collapsed={true}
              toggleable={true}
              legend="Color letra del cartel"
            >
              <div className="row mt-2 mb-2">
                <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                  <ColorPicker
                    id="color_letra"
                    value={this.state.colorLetra}
                    onChange={(e) => this.setState({ colorLetra: e.value })}
                  />
                </div>
                <div
                  style={{ color: "#" + this.state.colorLetra }}
                  className="col-5 col-sm-5 col-md-5 col-lg-5 "
                >
                  <h3>{this.t("GENERICO.COLOR_LETRA")}</h3>
                </div>
              </div>
            </Fieldset>
            <Fieldset
              className="mt-3 mb-3"
              toggleable={true}
              legend="Terminos y condiciones"
            >
              <div className="row mb-4 mb-md-3 mb-lg-3">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-4 mt-md-3 mt-lg-3 ">
                  <Editor
                    style={{ height: "320px" }}
                    value={this.state.terminosYCondiciones}
                    onTextChange={(e) => {
                      guardarEnLocalStorage(
                        "evento.terminosYCondiciones",
                        e.htmlValue
                      );
                      this.setState({ terminosYCondiciones: e.htmlValue });
                    }}
                  />
                </div>
              </div>
            </Fieldset>

            <div className="row d-flex flex-wrap justify-content-center mt-5">
              <div className="col-6 col-sm-4 col-md-6 col-lg-4 pb-4 ">
                <button
                  onClick={this.previsualizarEvento}
                  type="button"
                  className="btn btn-primary form-control"
                >
                  Previsualizar
                </button>
              </div>
              <div className="col-6 col-sm-4 col-md-6 col-lg-4 pb-4 ">
                <input
                  className="mb-2 btn btn-primary form-control"
                  type="submit"
                />
              </div>
            </div>
          </div>
        </form>

        <Dialog
          header="Previsualizacion"
          visible={this.state.mostrarPrevisualizacionEvento}
          onHide={() => this.setState({ mostrarPrevisualizacionEvento: false })}
          blockScroll
          maximized
          contentStyle={{ margin: "0", padding: "0" }}
        >
          <EventoPrevisualizacion
            evento={this.state.evento}
            esPrevisualizacion={true}
          />
        </Dialog>
      </>
    );
  }
}

//Se exporta con withTranslation para obtener las props de traducción
export default withTranslation()(EventoNuevo);
