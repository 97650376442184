import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import codigoService from "../../../services/codigoService/codigo-service";
import PropTypes from "prop-types";
import { EXPRESIONREGULAREMAIL } from "../../../utils/constantes/constantes";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import GrowlPesonalizado from "../../../components/growlPersonalizado/Growl-pesonalizado";
import { ordenarArrayPersonalizadoConObjetos } from "../../../utils/funciones/funciones";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";

export default function CodigoActivar(props) {
  const { t } = useTranslation();
  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [
    checkBoxTerminosYCondiciones,
    setCheckBoxTerminosYCondiciones,
  ] = useState(false);
  const [
    mostrarTerminosYCondiciones,
    setMostrarTerminosYCondiciones,
  ] = useState(false);
  const [telefono, setTelefono] = useState("");
  const [edad, setEdad] = useState("");
  const [sexo, setSexo] = useState("");

  let growlPersonalizado = React.useRef();

  function renderizarInputsObligatorios() {
    if (
      props.camposRegistroEventoObligatorios === null ||
      props.camposRegistroEventoObligatorios.length === undefined ||
      props.camposRegistroEventoObligatorios.length === 0
    ) {
      return (
        <div>
          <div className="row justify-content-center mt-2">
            <div className="col-xs-12 col-sm-5 col-md-4 col-lg-4 pb-4">
              <input
                className="form-control"
                placeholder={t("GENERICO.NOMBRE")}
                title={t("GENERICO.NOMBRE")}
                type="text"
                onChange={(e) => setNombre(e.target.value)}
                required
              ></input>
            </div>
            <div className="col-xs-12 col-sm-7 col-md-6 col-lg-6 pb-4">
              <input
                className="form-control"
                placeholder={t("GENERICO.APELLIDOS")}
                title={t("GENERICO.APELLIDOS")}
                type="text"
                onChange={(e) => setApellidos(e.target.value)}
                required
              ></input>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xs-12 col-sm-6 col-md-5 col-lg-5 pb-4">
              <input
                className="form-control"
                placeholder={t("GENERICO.EMAIL")}
                title={t("GENERICO.EMAIL")}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                pattern={EXPRESIONREGULAREMAIL}
                autoComplete="off"
              ></input>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-5 col-lg-5 pb-4">
              <input
                className="form-control"
                placeholder={t("GENERICO.CONFIRMAR_EMAIL")}
                title={t("GENERICO.CONFIRMAR_EMAIL")}
                type="email"
                onChange={(e) => setEmail2(e.target.value)}
                required
                pattern={EXPRESIONREGULAREMAIL}
                autoComplete="off"
              ></input>
            </div>
          </div>
        </div>
      );
    } else if (props.camposRegistroEventoObligatorios.length >= 1) {
      let elementosARenderizar = [];
      ordenarArrayPersonalizadoConObjetos(
        props.camposRegistroEventoObligatorios,
        "order"
      );
      for (let x = 0; x < props.camposRegistroEventoObligatorios.length; x++) {
        switchInputsObligatorios(
          elementosARenderizar,
          props.camposRegistroEventoObligatorios[x].value,
          x
        );
      }
      return (
        <div className="row justify-content-center">{elementosARenderizar}</div>
      );
    }
  }

  function switchInputsObligatorios(array, valorAComparar, indice) {
    switch (valorAComparar) {
      case "Nombre":
        array.push(
          <div
            key={indice}
            className="col-xs-12 col-sm-6 col-md-5 col-lg-5 pb-4"
          >
            <input
              className="form-control"
              placeholder={t("GENERICO.NOMBRE")}
              title={t("GENERICO.NOMBRE")}
              type="text"
              onChange={(e) => setNombre(e.target.value)}
              required
            ></input>
          </div>
        );
        break;
      case "Apellidos":
        array.push(
          <div
            key={indice}
            className="col-xs-12 col-sm-6 col-md-5 col-lg-5 pb-4"
          >
            <input
              className="form-control"
              placeholder={t("GENERICO.APELLIDOS")}
              title={t("GENERICO.APELLIDOS")}
              type="text"
              onChange={(e) => setApellidos(e.target.value)}
              required
            ></input>
          </div>
        );
        break;
      case "Edad":
        array.push(
          <div
            key={indice}
            className="col-xs-12 col-sm-6 col-md-5 col-lg-5 pb-4"
          >
            <input
              className="form-control"
              placeholder={t("GENERICO.EDAD")}
              title={t("GENERICO.EDAD")}
              type="number"
              value={edad}
              onChange={(e) => setEdad(e.target.value)}
              required
            ></input>
          </div>
        );
        break;
      case "Correo":
        array.push(
          <React.Fragment key={indice}>
            <div className="col-xs-12 col-sm-6 col-md-5 col-lg-5 pb-4">
              <input
                className="form-control"
                placeholder={t("GENERICO.EMAIL")}
                title={t("GENERICO.EMAIL")}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                pattern={EXPRESIONREGULAREMAIL}
                autoComplete="off"
              ></input>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-5 col-lg-5 pb-4">
              <input
                className="form-control"
                placeholder={t("GENERICO.CONFIRMAR_EMAIL")}
                title={t("GENERICO.CONFIRMAR_EMAIL")}
                type="email"
                onChange={(e) => setEmail2(e.target.value)}
                required
                pattern={EXPRESIONREGULAREMAIL}
                autoComplete="off"
              ></input>
            </div>
          </React.Fragment>
        );
        break;
      case "Teléfono":
        array.push(
          <div
            key={indice}
            className="col-xs-12 col-sm-6 col-md-5 col-lg-5 pb-4"
          >
            <InputMask
              id="telefono"
              name="telefono"
              mask="999-999-999"
              className="form-control"
              title={t("GENERICO.TELEFONO")}
              placeholder={t("GENERICO.TELEFONO")}
              required={true}
              value={telefono}
              onChange={(e) => setTelefono(e.value)}
              autoClear={false}
            ></InputMask>
          </div>
        );
        break;
      case "Sexo":
        let sexos = [
          { nombre: "Hombre", codigo: "H" },
          { nombre: "Mujer", codigo: "M" },
        ];
        array.push(
          <div
            key={indice}
            className="col-xs-12 col-sm-6 col-md-5 col-lg-5 pb-4"
          >
            <Dropdown
              required
              value={sexo}
              options={sexos}
              title={t("GENERICO.NOMBRE")}
              onChange={(e) => setSexo(e.value)}
              placeholder={t("GENERICO.SEXO")}
              optionLabel="nombre"
              style={{ width: "100%" }}
            />
          </div>
        );
        break;
      default:
        console.log("No existe");
        break;
    }
  }

  function registrarCodigo(event) {
    event.preventDefault();
    if (checkBoxTerminosYCondiciones && !props.esPrevisualizacion) {
      if (email === email2) {
        let usuario = {
          email: email,
          nombre: nombre,
          apellido: apellidos,
          casillasMarcadas: [
            checkBoxTerminosYCondiciones
              ? {
                  idCasilla: "TERMINOS_Y_CONDICIONES",
                  texto: props.htmlTerminosYCondiciones,
                  marcada: true,
                }
              : {
                  idCasilla: "TERMINOS_Y_CONDICIONES",
                  texto: props.htmlTerminosYCondiciones,
                  marcada: false,
                },
          ],
        };
        codigoService
          .asignarCodigo(props.codigo, usuario)
          .then((resp) => {
            growlPersonalizado.current.mostrarMensajePersonalizado({
              life: 10000,
              severity: "info",
              summary: t("MENSAJES.TITULO.CORRECTO"),
              detail: t("MENSAJES.CODIGO_ACTIVADO_OK"),
            });
          })
          .catch(function (error) {
            growlPersonalizado.current.mostrarMensajeError(
              t("MENSAJES.CODIGO_ACTIVADO_ERROR")
            );
          });
      } else {
        growlPersonalizado.current.mostrarMensajeAdvertencia(
          t("MENSAJES.EMAIL_ERROR")
        );
      }
    }
  }

  return (
    <form onSubmit={registrarCodigo}>
      <GrowlPesonalizado ref={growlPersonalizado} />

      <Dialog
        header="Terminos y condiciones"
        visible={mostrarTerminosYCondiciones}
        position="center"
        style={{
          minWidth: "40vw",
          header: "10px !important",
          maxHeight: "50vh",
        }}
        onHide={() => setMostrarTerminosYCondiciones(false)}
        blockScroll
      >
        <div>
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-12"
            dangerouslySetInnerHTML={{
              __html: props.htmlTerminosYCondiciones,
            }}
          ></div>
        </div>
      </Dialog>
      <div className="row justify-content-center mt-4">
        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 pb-4 ">
          <input
            className="form-control text-center"
            placeholder={t("GENERICO.CODIGO")}
            title={t("GENERICO.CODIGO")}
            type="text"
            disabled={true}
            value={props.codigo}
          ></input>
        </div>
      </div>
      {renderizarInputsObligatorios()}
      <div className="row justify-content-center">
        <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 p-1 m-2 caja-transparente-blanca text-dark text-center">
          <Checkbox
            checked={checkBoxTerminosYCondiciones}
            onChange={(e) => setCheckBoxTerminosYCondiciones(e.checked)}
            className="mt-lg-0 mr-3"
          />
          <span className="terminoYCondiciones">
            <Trans i18nKey="TERMINOS.ACEPTO_CONDICIONES">
              Acepta las condiciones particulares de este evento recogidas en
              este
              <span
                className="text-primary cursor-pointer"
                onClick={() => setMostrarTerminosYCondiciones(true)}
              >
                enlace
              </span>
            </Trans>
          </span>
        </div>
      </div>
      <div
        className="justify-content-center mt-1"
        style={{ borderTop: "1px solid black" }}
      ></div>
      <div className="row justify-content-center mt-4">
        <div className="col-xs-12 col-sm-5 col-md-4 col-lg-4 pb-4">
          <input
            className="btn btn-primary form-control"
            placeholder={t("GENERICO.NOMBRE")}
            type="submit"
            disabled={!checkBoxTerminosYCondiciones}
            value={t("EVENTO.REGISTRARME")}
          ></input>
        </div>
      </div>
    </form>
  );
}

CodigoActivar.defaultProps = {
  esPrevisualizacion: false,
};

CodigoActivar.propTypes = {
  codigo: PropTypes.string.isRequired,
  htmlTerminosYCondiciones: PropTypes.string.isRequired,
  esPrevisualizacion: PropTypes.bool,
};
