import React, { useState } from "react";
import "./login.css";
import { InputText } from "primereact/inputtext";
import {
  autenticacionCognito,
  enviarCodigoVerificacionCognito,
  restablecerContraseniaCognito,
} from "../../utils/funciones/funciones";
import { useHistory } from "react-router-dom";
import GrowlPesonalizado from "../../components/growlPersonalizado/Growl-pesonalizado";
import { useTranslation } from "react-i18next";
import { EXPRESIONREGULAREMAIL } from "../../utils/constantes/constantes";

export default function Login() {
  let history = useHistory();
  const { t } = useTranslation();
  //Login
  const [emailLogin, setEmailLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  //Restablecer contraseña
  const [emailRestablecer, setEmailRestablecer] = useState("");
  const [
    passwordConfirmacionRestablecer,
    setPasswordConfirmacionRestablecer,
  ] = useState("");
  const [passwordRestablecer, setPasswordRestablecer] = useState("");
  const [codigoCognito, setCodigoCognito] = useState("");
  const [
    enviadoCodigoRestablecerContrasenia,
    setEnviadoCodigoRestablecerContrasenia,
  ] = useState(false);
  //Render vista
  const [vistaARenderizar, setVistaARenderizar] = useState("LOGIN");
  let growlPersonalizado = React.useRef();

  async function autenticar(event) {
    event.preventDefault();
    autenticacionCognito(emailLogin, passwordLogin)
      .then((response) => {
        history.push("/admin");
      })
      .catch((error) => {
        growlPersonalizado.current.mostrarMensajeError(
          "Error al autenticar compruebe el correo o contraseña"
        );
      });
  }

  function restablecerContrasenia(event) {
    event.preventDefault();

    if (enviadoCodigoRestablecerContrasenia) {
      restablecerContraseniaCognito(
        emailRestablecer,
        codigoCognito,
        passwordConfirmacionRestablecer
      )
        .then((data) => {
          setEnviadoCodigoRestablecerContrasenia(false);
          setEmailRestablecer("");
          setCodigoCognito("");
          setPasswordConfirmacionRestablecer("");
          growlPersonalizado.current.mostrarMensajeCorrecto(
            t("MENSAJES.LOGIN_PASSWORD_CAMBIADA_OK")
          );
        })
        .catch((err) => {
          if (err.code === "InvalidPasswordException") {
            growlPersonalizado.current.mostrarMensajeCorrecto(
              t("MENSAJES.LOGIN_PASSWORD_CONDICIONES")
            );
          } else {
            growlPersonalizado.current.mostrarMensajeCorrecto(
              t("MENSAJES.LOGIN_PASSWORD_CAMBIADA_ERROR")
            );
          }
        });
    } else {
      enviarCodigoVerificacionCognito(emailRestablecer)
        .then((success) => {
          setEnviadoCodigoRestablecerContrasenia(true);
          growlPersonalizado.current.mostrarMensajeCorrecto(
            t("MENSAJES.COGNITO_CODIGO_VERIFICACION_OK")
          );
        })
        .catch((error) => {
          console.log(error);
          growlPersonalizado.current.mostrarMensajeError(
            t("MENSAJES.COGNITO_CODIGO_VERIFICACION_ERROR")
          );
        });
    }
    // setVistaARenderizar("LOGIN");
  }

  function renderizarVistas(vista) {
    switch (vista) {
      case "LOGIN":
        return (
          <>
            <h5 className="card-title text-center">Login</h5>
            <form className="form-signin" onSubmit={autenticar}>
              <div className="form-label-group">
                <span className="p-float-label">
                  <InputText
                    id="emailLogin"
                    className="form-control"
                    type="email"
                    value={emailLogin}
                    onChange={(e) => setEmailLogin(e.target.value)}
                    required={true}
                    pattern={EXPRESIONREGULAREMAIL}
                  ></InputText>
                  <label htmlFor="emailLogin">{t("GENERICO.EMAIL")}</label>
                </span>
              </div>

              <div className="form-label-group">
                <span className="p-float-label">
                  <InputText
                    id="passwordLogin"
                    className="form-control"
                    type="password"
                    value={passwordLogin}
                    onChange={(e) => setPasswordLogin(e.target.value)}
                    required={true}
                  ></InputText>
                  <label htmlFor="passwordLogin">
                    {t("GENERICO.CONTRASENIA")}
                  </label>
                </span>
              </div>

              <button
                className="btn btn-lg btn-primary btn-block text-uppercase"
                type="submit"
              >
                {t("GENERICO.ENVIAR")}
              </button>
              <hr className="my-4" />
              <span
                onClick={() => setVistaARenderizar("RESETCONTRASENIA")}
                className="text-primary small cursor-pointer"
              >
                {t("LOGIN.RESTABLECER_CONTRASENIA")}
              </span>
            </form>
          </>
        );
      case "RESETCONTRASENIA":
        return (
          <>
            <h5 className="card-title text-center">
              {t("LOGIN.RESTABLECER_CONTRASENIA")}
            </h5>
            <form className="form-signin" onSubmit={restablecerContrasenia}>
              {!enviadoCodigoRestablecerContrasenia ? (
                <div className="form-label-group">
                  <span className="p-float-label">
                    <InputText
                      id="emailRestablecer"
                      className="form-control"
                      type="email"
                      value={emailRestablecer}
                      onChange={(e) => setEmailRestablecer(e.target.value)}
                      required={true}
                      pattern={EXPRESIONREGULAREMAIL}
                    ></InputText>
                    <label htmlFor="emailRestablecer">
                      {t("GENERICO.EMAIL")}
                    </label>
                  </span>
                </div>
              ) : (
                <>
                  <div className="form-label-group">
                    <span className="p-float-label">
                      <InputText
                        id="codigoVerificacion"
                        className="form-control"
                        type="text"
                        value={codigoCognito}
                        onChange={(e) => setCodigoCognito(e.target.value)}
                        required={true}
                      ></InputText>
                      <label htmlFor="codigoVerificacion">
                        {t("LOGIN.CODIGO_VERIFICACION")}
                      </label>
                    </span>
                  </div>
                  <div className="form-label-group">
                    <span className="p-float-label">
                      <InputText
                        id="passwordRestablecer"
                        className="form-control"
                        type="password"
                        value={passwordRestablecer}
                        onChange={(e) => setPasswordRestablecer(e.target.value)}
                        required={true}
                      ></InputText>
                      <label htmlFor="passwordRestablecer">
                        {t("GENERICO.CONTRASENIA")}
                      </label>
                    </span>
                  </div>
                  <div className="form-label-group">
                    <span className="p-float-label">
                      <InputText
                        id="passwordConfirmacionRestablecer"
                        className="form-control"
                        type="password"
                        value={passwordConfirmacionRestablecer}
                        onChange={(e) =>
                          setPasswordConfirmacionRestablecer(e.target.value)
                        }
                        required={true}
                      ></InputText>
                      <label htmlFor="passwordConfirmacionRestablecer">
                        {t("LOGIN.CONFIRME_CONTRASENIA")}
                      </label>
                    </span>
                  </div>
                </>
              )}

              <button
                className="btn btn-lg btn-primary btn-block text-uppercase"
                type="submit"
              >
                {t("GENERICO.ENVIAR")}
              </button>
              <hr className="my-4" />
              <span
                onClick={() => {
                  setVistaARenderizar("LOGIN");
                  setEnviadoCodigoRestablecerContrasenia(false);
                }}
                className="text-primary small cursor-pointer"
              >
                {t("LOGIN.ACCEDE_A_SU_CUENTA")}
              </span>
            </form>
          </>
        );

      default:
        return "foo";
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div className="card card-signin my-5">
            <div className="card-body">
              <GrowlPesonalizado ref={growlPersonalizado} />
              {renderizarVistas(vistaARenderizar)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
