import axios from "axios";

import {
  recuperarValorLocalStorage,
  refrescarCognitoTokenIdYRefreshToken,
  eliminarTodosLosElementosLocalStorage,
} from "../funciones/funciones";
// Add a request interceptor
let contador = 0;
let contadorIntentoRefrescarToken = 1;

function restarContadorYOcultarSpinnerAlTerminar() {
  contador--;
  if (contador === 0) {
    document.body.classList.remove("spinner");
  }
}

async function intentarRefrescarTokenYRestarCantidadDeIntentosOVolverALogin(
  error
) {
  await refrescarCognitoTokenIdYRefreshToken();

  if (contadorIntentoRefrescarToken === 1) {
    contadorIntentoRefrescarToken--;
    //Se hace return porque de lo contrario axios no ejecuta la llamada.
    return axios.request(error.config);
  } else {
    eliminarTodosLosElementosLocalStorage();
    return (window.location.href = "/login");
  }
}

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    //config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Authorization"] = recuperarValorLocalStorage(
      "tokenAuthorization"
    );
    //Añade el spinner css
    contador++;
    document.body.classList.add("spinner");
    document.body.focus();

    return config;
  },
  function (error) {
    // Do something with request error
    restarContadorYOcultarSpinnerAlTerminar();
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    //Elimina el spinner css
    restarContadorYOcultarSpinnerAlTerminar();
    return response;
  },
  function (error) {
    restarContadorYOcultarSpinnerAlTerminar();

    if (error.config && error.response && error.response.status === 401) {
      //Se hace return para que axios no continue la cadena de interceptores.
      return intentarRefrescarTokenYRestarCantidadDeIntentosOVolverALogin(
        error
      );
    }

    return Promise.reject(error);
  }
);
