import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import TarjetaPersonalizada from "../../components/tarjetaPersonalizada/Tarjeta-personalizada";

class MenuAdministrador extends Component {
  render() {
    return (
      <div className="container d-flex flex-nowrap justify-content-center align-items-center">
        <TarjetaPersonalizada
          titulo={this.props.t("EVENTO.CREAR")}
          enlace="/evento/nuevo"
          contenido="Sección de crear evento"
          imgCabecera="https://www.forman.es/wp-content/uploads/2015/11/contratar-famosos.jpg"
        />

        <TarjetaPersonalizada
          titulo={this.props.t("TITULO.LISTA_EVENTOS")}
          enlace="/evento"
          contenido="Sección eventos"
          imgCabecera="https://programacion.net/files/article/article_02037_.png"
        />
      </div>
    );
  }
}

//Se exporta con withTranslation para obtener las props de traducción
export default withTranslation()(MenuAdministrador);
