import React from "react";
import "./App.css";
import RutasApp from "./components/rutasApp/Rutas-app";
//Configuracion de interceptor de axios
import "./utils/interceptor/interceptor-service";

function App() {
  return (
    <>
      <RutasApp />
    </>
  );
}

export default App;
