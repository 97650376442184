import React from "react";
import { withTranslation } from "react-i18next";
//Servicios
import { Dropdown } from "primereact/dropdown";

import PropTypes from "prop-types";
import ToolbarPersonalizado from "../../../components/toolbarPersonalizado/Toolbar-personalizado";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { withRouter } from "react-router-dom";
import { obtenerPaginasPaginador } from "../../../utils/funciones/funciones";
import { compose } from "redux";
import CodigoNuevo from "../codigoNuevo/Codigo-nuevo";
import CodigoService from "../../../services/codigoService/codigo-service";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

class CodigoLista extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codigos: [],
      first: 0,
      rows: 10,
      totalRecords: 0,
      paginasPaginador: 0,
      tokenBusqueda: null,
      filtroEstadoCodigo: {},
      filtrosActivados: false,
      idEvento: props.match.params.idEvento,
    };
    this.datasourceCodigos = [];
  }

  //Metodo que solo se ejecuta al montar el componente (Ciclo de vida de React)
  componentDidMount() {
    this.obtenerCodigos(
      100,
      null,
      this.state.idEvento,
      null,
      null,
      null,
      false
    );
  }

  actualizarPaginador(arrayCodigos, tokenBusqueda, onPage) {
    this.datasourceCodigos = this.datasourceCodigos.concat(arrayCodigos);
    const paginasPaginador = obtenerPaginasPaginador(
      this.datasourceCodigos,
      this.state.rows
    );
    //this.datasource = response.data.contenido;
    this.setState({
      tokenBusqueda: tokenBusqueda || null,
      paginasPaginador: paginasPaginador,
      totalRecords: this.datasourceCodigos.length,
    });
    if (onPage === false) {
      this.setState({
        first: 0,
        codigos: this.datasourceCodigos.slice(0, this.state.rows),
      });
    }
  }

  filtrarBusquedaCodigos(filtrosActivados) {
    let filtroEstadoCodigo;
    this.datasourceCodigos = [];
    this.setState({ filtrosActivados: filtrosActivados });

    if (!filtrosActivados) {
      filtroEstadoCodigo = null;
      this.setState({ filtroEstadoCodigo: {} });
    } else {
      filtroEstadoCodigo = this.state.filtroEstadoCodigo.code;
    }
    this.obtenerCodigos(
      100,
      null,
      this.state.idEvento,
      filtroEstadoCodigo,
      null,
      null,
      false
    );
  }

  obtenerCodigos = (
    cantidad,
    tokenBusqueda,
    idEvento,
    estado,
    fechaAsignacionDesde,
    fechaAsignacionHasta,
    onPage
  ) => {
    CodigoService.listarCodigos(
      cantidad,
      tokenBusqueda,
      idEvento,
      estado,
      fechaAsignacionDesde,
      fechaAsignacionHasta
    ).then((response) => {
      this.actualizarPaginador(
        response.data.contenido,
        response.data.tokenBusqueda,
        onPage
      );
    });
  };

  onPage(event) {
    const startIndex = event.first;
    const endIndex = event.first + this.state.rows;
    this.setState({
      first: startIndex,
      codigos: this.datasourceCodigos.slice(startIndex, endIndex),
    });

    if (
      this.state.tokenBusqueda != null &&
      this.state.paginasPaginador - 2 === event.page
    ) {
      let estado;
      if (this.state.filtrosActivados) {
        estado = this.state.filtroEstadoCodigo.code;
      } else {
        estado = null;
      }

      this.obtenerCodigos(
        100,
        this.state.tokenBusqueda,
        this.state.idEvento,
        estado,
        null,
        null,
        true
      );
    }
  }

  plantillaAcciones(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-danger mt-2 mt-md-2 mt-lg-0"
          style={{ marginRight: ".5em" }}
        ></Button>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const estadosCodigo = [
      { name: "Disponible", code: "DISPONIBLE" },
      { name: "Activado", code: "ACTIVADO" },
    ];
    return (
      <>
        <ToolbarPersonalizado
          titulo={t("TITULO.LISTA_CODIGOS")}
          mostrarBotonRetrocederPagina={true}
        />
        <div className="container">
          <Panel
            header={t("CODIGO.GENERAR")}
            style={{ marginTop: "2em" }}
            toggleable={true}
            collapsed={true}
          >
            <CodigoNuevo />
          </Panel>
          <Panel
            header={t("GENERICO.FILTROS")}
            style={{ marginTop: "2em" }}
            toggleable={true}
          >
            <Dropdown
              className="ml-3 mt-2 mt-md-2 mt-lg-2 mb-2 mb-md-2 mb-lg-2"
              options={estadosCodigo}
              placeholder="Estado"
              optionLabel="name"
              style={{ width: "12em" }}
              value={this.state.filtroEstadoCodigo}
              onChange={(e) => this.setState({ filtroEstadoCodigo: e.value })}
            />
            <div className="d-flex justify-content-center">
              <div className="col-5 col-sm-3 col-md-3 col-lg-2">
                <Button
                  label={t("GENERICO.FILTRAR")}
                  icon="pi pi-filter"
                  iconPos="right"
                  onClick={() => {
                    this.filtrarBusquedaCodigos(true);
                  }}
                  className=""
                />
              </div>
              <div className="col-5 col-sm-3 col-md-3 col-lg-2">
                <Button
                  label={t("GENERICO.LIMPIAR")}
                  icon="pi pi-trash"
                  iconPos="right"
                  className=""
                  onClick={() => {
                    this.filtrarBusquedaCodigos(false);
                  }}
                />
              </div>
            </div>
          </Panel>

          <DataTable
            className="mt-4"
            value={this.state.codigos}
            lazy={true}
            paginator={true}
            paginatorTemplate="PrevPageLink NextPageLink"
            rows={this.state.rows}
            first={this.state.first}
            totalRecords={this.state.totalRecords}
            onPage={(e) => this.onPage(e)}
          >
            <Column
              field="idCodigo"
              header="Código"
              style={{ textAlign: "center" }}
            />
            <Column
              field="estado"
              header="Estado"
              style={{ textAlign: "center" }}
            />

            <Column
              body={this.plantillaAcciones}
              style={{ textAlign: "center" }}
              header="Acciones"
            />
          </DataTable>
        </div>
      </>
    );
  }
}
export default compose(withTranslation(), withRouter)(CodigoLista);

CodigoLista.propTypes = {
  onChangeEvento: PropTypes.func,
  onChangeCodigo: PropTypes.func,
  esPrevisualizacion: PropTypes.bool,
};
