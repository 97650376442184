import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PaginaNoEncontrada from "../paginaNoEncontrada/Pagina-no-encontrada";
/* import Login from "../../modules/login/Login";*/
import EventoNuevo from "../../modules/eventos/eventoNuevo/Evento-nuevo";
import MenuAdministrador from "../../modules/menu/Menu-administrador";
import Cabecera from "../../components/cabecera/Cabecera";
//import CodigoValidar from "../../modules/codigos/codigoValidar/Codigo-validar";
import EventoPrevisualizacion from "../../modules/eventos/eventoPrevisualizacion/Evento-previsualizacion";
import Login from "../../modules/login/Login";
import EventoLista from "../../modules/eventos/eventoLista/Evento-lista";
import CodigoLista from "../../modules/codigos/codigoLista/Codigo-lista";

export default class RutasApp extends Component {
  render() {
    return (
      <div className="AppReact">
        <Router>
          <Cabecera />
          <Switch>
            {/* Login entrada */}
            {/*<Route exact path="/login" component={Login} />*/}

            {/* Cuerpo de aplicación */}
            <Route exact path="/evento/nuevo" component={EventoNuevo} />
            <Route exact path="/evento" component={EventoLista} />
            <Route
              exact
              path="/evento/:idEvento/codigos"
              component={CodigoLista}
            />
            <Route exact path="/admin" component={MenuAdministrador} />
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/:idCodigo([0-9]{4}|[0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4})?"
              component={EventoPrevisualizacion}
            />

            {/* Si no existe una ruta  */}
            <Route exact path="*">
              <Redirect to="/404" />
              <PaginaNoEncontrada />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}
