import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { FileUpload } from "primereact/fileupload";

export default function FileUploadPersonalizado(props) {
  const [previsualizacionFile, setPrevisualizacionFile] = useState(null);
  const fileUploadRef = useRef();

  function transformarFileABase64YLimpiarFileUpload(e) {
    var file = e.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      props.statePrevisualizacion(reader.result);
      setPrevisualizacionFile(reader.result);
      //Limpia el componente sino no se puede a volver a seleccionar otra imagen.
      fileUploadRef.current.clear();
    };
  }

  function eliminarImagen() {
    props.statePrevisualizacion(null);
    setPrevisualizacionFile(null);
  }
  return (
    <div>
      <FileUpload
        ref={fileUploadRef}
        mode="basic"
        accept="image/*"
        maxFileSize={10000000}
        auto={true}
        chooseLabel={props.nombreBoton}
        customUpload={true}
        uploadHandler={transformarFileABase64YLimpiarFileUpload}
        style={{ width: "100%" }}
      />
      {previsualizacionFile != null && (
        <div>
          <div
            style={{
              position: "absolute",
              left: "125px",
              top: "30px",
            }}
          >
            <i
              className="fa fa-times fa-lg"
              onClick={eliminarImagen}
              aria-hidden="true"
            ></i>
          </div>
          <div className="mt-2">
            <img
              alt="prev"
              style={{ height: "100px", width: "120px" }}
              src={previsualizacionFile}
            />
          </div>
        </div>
      )}
    </div>
  );
}

FileUploadPersonalizado.propTypes = {
  nombreBoton: PropTypes.string.isRequired,
  statePrevisualizacion: PropTypes.func.isRequired,
};
