/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  Auth: {
    // Amazon Cognito Region
    region: "us-west-1",

    // Amazon Cognito User Pool ID
    userPoolId: "eu-west-1_lrT7jUP1S",

    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "mfv1ocak1easgrj2j6bsp60t7",
  },
};

export default awsmobile;
