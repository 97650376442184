import axios from "axios";

export default class CodigoService {
  /**
 * Servicio con la llamada (urlApi/codigo/:codigo)
 * Asigna el codigo a un correo
 * parametrosUsuario ej : 
 * {
    "email": "gabriel.gonzalez@movired.com",
    "nombre": "Gabriel",
    "apellido": "Gonzalez"
    }
 * 
 * @param {string} codigo 
 * @param {Object} parametrosUsuario 
 * @returns axios
 */
  static asignarCodigo(codigo, parametrosUsuario) {
    return axios.put(
      process.env.REACT_APP_API_PUBLIC_URL + "codigos/" + codigo,
      parametrosUsuario
    );
  }
  /**
   * Servicio con la llamada (urlApi/codigo/:codigo)
   * Recupera el estado del codigo (DISPONIBLE,ACTIVADO,NOEXISTE)
   *
   * @param {string} codigo
   * @returns axios
   */
  static recuperarCodigoEstado(codigo) {
    return axios.get(
      process.env.REACT_APP_API_PUBLIC_URL + "codigos/" + codigo
    );
  }

  /**
   * Servicio con la llamada (urlApi/codigos?tamanioMinimo=&tokenBusqueda=&idEvento=&estado=&fechaAsignacionDesde=&fechaAsignacionHasta=)
   * Devuelve lista de códigos paginada con los parametros indicados
   *
   * @param {number} tamanioMinimo
   * @param {string} tokenBusqueda
   * @param {string} idEvento
   * @param {string} estado
   * @param {string} fechaAsignacionDesde
   * @param {string} fechaAsignacionHasta
   *
   * @returns axios
   */
  static listarCodigos(
    tamanioMinimo,
    tokenBusqueda,
    idEvento,
    estado,
    fechaAsignacionDesde,
    fechaAsignacionHasta
  ) {
    idEvento = idEvento !== null ? "&idEvento=" + idEvento : "";
    estado = estado !== null ? "&estado=" + estado : "";
    tamanioMinimo =
      tamanioMinimo !== null
        ? "tamanioMinimo=" + tamanioMinimo
        : "tamanioMinimo=100";
    tokenBusqueda =
      tokenBusqueda !== null ? "&tokenBusqueda=" + tokenBusqueda : "";
    fechaAsignacionDesde =
      fechaAsignacionDesde !== null
        ? "fechaAsignacionDesde=" + fechaAsignacionDesde
        : "";
    fechaAsignacionHasta =
      fechaAsignacionHasta !== null
        ? "fechaAsignacionHasta=" + fechaAsignacionHasta
        : "";

    return axios.get(
      process.env.REACT_APP_API_ADMIN_URL +
        "codigos?" +
        tamanioMinimo +
        tokenBusqueda +
        idEvento +
        estado +
        fechaAsignacionDesde +
        fechaAsignacionHasta
    );
  }
}
