import axios from "axios";

export default class EventoService {
  /**
   * Servicio con la llamada (urlApi/evento/:idEvento)
   * Realiza una busqueda por id del evento
   *
   * @param {string} idEvento
   * @returns axios
   */
  static recuperarEvento(idEvento) {
    return axios.get(
      process.env.REACT_APP_API_PUBLIC_URL + "eventos/" + idEvento
    );
  }

  /**
   * Servicio con la llamada POST (urlApi/evento)
   * Guarda el objeto Evento
   *
   * @param {object} evento
   * @returns axios
   */
  static crearEvento(evento) {
    return axios.post(process.env.REACT_APP_API_ADMIN_URL + "eventos", evento);
  }

  /**
   * Servicio con la llamada GET (urlApi/eventos?cantidad=X&tokenBusqueda=X)
   * Guarda el objeto Evento
   * tamanioMinimo=1&tokenBusqueda=undefined&titulo=2021&fechaInicioEventoDesde=undefined&fechaInicioEventoHasta=undefined
   * &artista=undefined&editor=undefined&categoriaProducto=undefined&tituloProducto=undefined&organizador=undefined
   *
   * @param {String} cantidad
   * @param {String} tokenBusqueda
   * @returns axios
   */
  static listarEventos(
    tamanioMinimo,
    tokenBusqueda,
    titulo,
    fechaInicioEventoDesde,
    fechaInicioEventoHasta,
    artista,
    editor,
    categoriaProducto,
    tituloProducto
  ) {
    tamanioMinimo =
      tamanioMinimo !== null
        ? "tamanioMinimo=" + tamanioMinimo
        : "tamanioMinimo=100";
    tokenBusqueda =
      tokenBusqueda !== null ? "&tokenBusqueda=" + tokenBusqueda : "";
    titulo = titulo !== null ? "&titulo=" + titulo : "";
    fechaInicioEventoDesde =
      fechaInicioEventoDesde !== null
        ? "&fechaInicioEventoDesde=" + fechaInicioEventoDesde
        : "";
    fechaInicioEventoHasta =
      fechaInicioEventoHasta !== null
        ? "&fechaInicioEventoHasta=" + fechaInicioEventoHasta
        : "";
    artista = artista !== null ? "&artista=" + artista : "";
    editor = editor !== null ? "&editor=" + editor : "";
    categoriaProducto =
      categoriaProducto !== null
        ? "&categoriaProducto=" + categoriaProducto
        : "";
    tituloProducto =
      tituloProducto !== null ? "&tituloProducto=" + tituloProducto : "";
    return axios.get(
      process.env.REACT_APP_API_ADMIN_URL +
        "eventos?" +
        tamanioMinimo +
        tokenBusqueda +
        titulo +
        fechaInicioEventoDesde +
        fechaInicioEventoHasta +
        artista +
        editor +
        categoriaProducto +
        tituloProducto
    );
  }

  /**
   * Servicio con la llamada (urlApi/evento/:idEvento/crearCodigos?cantidad)
   * Genera codigos indicando el idEvento y la cantidad necesaria
   *
   * @param {string} idEvento
   * @param {number} cantidad
   * @returns axios
   */
  static crearCodigos(idEvento, cantidad) {
    return axios.post(
      process.env.REACT_APP_API_ADMIN_URL +
        "eventos/" +
        idEvento +
        "/crear-codigos?cantidad=" +
        cantidad
    );
  }
}
