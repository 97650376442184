import React, { Component } from "react";
import { Sidebar } from "primereact/sidebar";

export default class SidebarPersonalizado extends Component {
  constructor() {
    super();
    this.state = {
      visible: false,
    };
  }
  render() {
    return (
      <>
        <i
          className="cursor-pointer fa fa-bars fa-lg"
          onClick={() => this.setState({ visible: true })}
          aria-hidden="true"
        ></i>

        <Sidebar
          visible={this.state.visible}
          onHide={(e) => this.setState({ visible: false })}
        >
          <h1 style={{ fontWeight: "normal" }}>Menú</h1>
        </Sidebar>
      </>
    );
  }
}
