import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";

export default function ToolbarPersonalizado(props) {
  let history = useHistory();

  return (
    <Toolbar>
      <div className="p-toolbar-group-left">
        <span
          style={{ marginRight: ".25em", fontSize: "16px", fontWeight: "bold" }}
        >
          {props.titulo}
        </span>
      </div>
      {/* Condicional para mostrar boton de retroceder pagina, si es true muestra el div si es false no */}
      {props.mostrarBotonRetrocederPagina && (
        <div className="p-toolbar-group-right">
          <i
            onClick={() => history.goBack()}
            className="pi pi-chevron-circle-left"
            style={{ fontSize: "22px", lineHeight: "25px", cursor: "pointer" }}
          ></i>
        </div>
      )}
    </Toolbar>
  );
}

ToolbarPersonalizado.defaultProps = {
  mostrarBotonRetrocederPagina: false,
};

ToolbarPersonalizado.propTypes = {
  titulo: PropTypes.string.isRequired,
  mostrarBotonRetrocederPagina: PropTypes.bool,
};
