import React, { Component } from "react";
import PropTypes from "prop-types";
import CodigoActivar from "../../codigos/codigoActivar/Codigo-activar";
import {
  pintarFechaATexto,
  cambiarUrlDeLaPagina,
} from "../../../utils/funciones/funciones";
import { withTranslation } from "react-i18next";
import CodigoValidar from "../../codigos/codigoValidar/Codigo-validar";
import GrowlPesonalizado from "../../../components/growlPersonalizado/Growl-pesonalizado";

class EventoPrevisualizacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disponible: true,
      evento: props.evento || {},
      esPrevisualizacion: props.esPrevisualizacion || false,
      resultadoCodigo: {},
    };
    this.growlPersonalizado = React.createRef();
  }

  switchCaseRedesSociales(redSocial, indice) {
    //Quitamos espacios y lo ponemos en mayusculas
    let nombreRedSocialTrimYUpper = redSocial.tipo.trim().toUpperCase();
    switch (nombreRedSocialTrimYUpper) {
      case "FACEBOOK":
        return (
          <a
            key={indice}
            href={redSocial.url}
            className="mr-2 cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-facebook-square fa-3x" aria-hidden="true"></i>
          </a>
        );
      case "INSTAGRAM":
        return (
          <a
            key={indice}
            href={redSocial.url}
            target="_blank"
            rel="noopener noreferrer"
            className="mr-2 cursor-pointer"
            style={{ color: "#cd2f95" }}
          >
            <i className="fa fa-instagram fa-3x" aria-hidden="true"></i>
          </a>
        );
      default:
        console.log(
          "Red social no añadida comprobar: " + nombreRedSocialTrimYUpper
        );
        break;
    }
  }

  setearResultadoCodigo = (event) => {
    let eventoEncontrado = true;
    switch (event.estado) {
      case "DISPONIBLE":
        this.growlPersonalizado.current.mostrarMensajeCorrecto(
          this.props.t("MENSAJES.CODIGO_VALIDADO_OK")
        );
        break;
      case "CODIGO_NO_ENCONTRADO":
        this.growlPersonalizado.current.mostrarMensajeError(
          this.props.t("MENSAJES.CODIGO_VALIDADO_ERROR")
        );
        break;
      case "ACTIVADO":
        this.growlPersonalizado.current.mostrarMensajeAdvertencia(
          this.props.t("MENSAJES.CODIGO_CANJEADO") + event.email
        );
        break;
      case "CADUCADO":
        this.growlPersonalizado.current.mostrarMensajeError(
          this.props.t("MENSAJES.CODIGO_VALIDADO_ERROR")
        );
        break;
      default:
        eventoEncontrado = false;
        console.log("Evento no esperado" + event.Resultado);
    }
    if (eventoEncontrado) {
      //Actualiza la url con el codigo
      cambiarUrlDeLaPagina(event.idCodigo);

      this.setState({ resultadoCodigo: event });
    }
  };
  render() {
    return (
      <div style={{ color: this.state.evento.colorLetra }}>
        <GrowlPesonalizado ref={this.growlPersonalizado} />

        <div
          style={{
            width: "100%",
            height: "100%",
            position: "fixed",
            backgroundImage: "url(" + this.state.evento.imagenFondo + ")",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        />
        <div className="container">
          {Object.keys(this.state.evento).length !== 0 ? (
            <>
              <div className="row justify-content-center">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2 mb-1 text-center">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.evento.organizador.url}
                  >
                    <img
                      src={this.state.evento.organizador.logo}
                      style={{ width: "160px", height: "70px" }}
                      alt={this.state.evento.organizador.nombre}
                    />
                  </a>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2 text-center">
                  <span>
                    {this.state.evento.organizador.nombre}{" "}
                    {this.props.t("GENERICO.TE_INVITA_A")}
                  </span>
                </div>
                <div
                  className={`m-2 text-dark ${
                    this.state.evento.imagenFondo !== "" &&
                    "caja-transparente-blanca"
                  }`}
                >
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 pb-1 mt-2">
                    <h2 style={{ textAlign: "center" }}>
                      {this.state.evento.titulo}
                    </h2>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                    <h4 style={{ textAlign: "center" }}>
                      {pintarFechaATexto(this.state.evento.fechaInicioEvento)}
                    </h4>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                    <h4 style={{ textAlign: "center" }}>
                      {this.props.t("GENERICO.INSCRIBETE_DEL")}
                      {pintarFechaATexto(
                        this.state.evento.fechaInicioRegistro
                      )}{" "}
                      -{" "}
                      {pintarFechaATexto(this.state.evento.fechaLimiteRegistro)}
                    </h4>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3 mb-2 text-center ">
                    <span>{this.state.evento.cuerpoMensaje}</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="row justify-content-center">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-3 mt-4">
                <h3 style={{ textAlign: "center" }}>
                  {/* Por favor, introduce la clave proporcionada para asociar a tu
                  correo. */}
                  {this.props.t("CODIGO.PARA_ACTIVAR")}
                </h3>
                <h6 style={{ textAlign: "center" }}>
                  {this.props.t("CODIGO.FORMATO")}
                </h6>
              </div>
            </div>
          )}

          <div
            className="mt-2"
            style={{ position: "relative", borderTop: "1px solid black" }}
          ></div>

          {this.state.esPrevisualizacion === true ? (
            <CodigoActivar
              htmlTerminosYCondiciones={this.state.evento.terminosYCondiciones}
              codigo="1234-1234-1234"
              camposRegistroEventoObligatorios={
                this.state.evento.camposRegistroEventoObligatorios
              }
              esPrevisualizacion={true}
            />
          ) : (
            <>
              {this.state.resultadoCodigo.estado === "DISPONIBLE" ? (
                <CodigoActivar
                  codigo={this.state.resultadoCodigo.idCodigo}
                  htmlTerminosYCondiciones={
                    this.state.evento.terminosYCondiciones || ""
                  }
                  camposRegistroEventoObligatorios={
                    this.state.evento.camposRegistroEventoObligatorios
                  }
                />
              ) : (
                <CodigoValidar
                  onChangeEvento={(e) => this.setState({ evento: e })}
                  onChangeCodigo={(e) => this.setearResultadoCodigo(e)}
                />
              )}
            </>
          )}

          {Object.keys(this.state.evento).length !== 0 &&
            this.state.evento.paginas.length > 0 && (
              <div className="row d-flex flex-wrap justify-content-center position-relative mt-3">
                {/* Recorremos el array de paginas que contienen las redes sociales*/}
                {this.state.evento.paginas.map(
                  /* Switch case en react, hay diferentes formas de hacerlo, pero esta me ha parecido mas elegante */
                  (redSocial, i) => this.switchCaseRedesSociales(redSocial, i)
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(EventoPrevisualizacion);

EventoPrevisualizacion.propTypes = {
  evento: PropTypes.object,
  esPrevisualizacion: PropTypes.bool,
};
