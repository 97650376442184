import React from "react";
import { withTranslation } from "react-i18next";
//Servicios
import eventoService from "../../../services/eventoService/evento-service";
import PropTypes from "prop-types";
import ToolbarPersonalizado from "../../../components/toolbarPersonalizado/Toolbar-personalizado";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { pintarFechaATexto } from "../../../utils/funciones/funciones";

import { obtenerPaginasPaginador } from "../../../utils/funciones/funciones";

class EventoLista extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventos: [],
      first: 0,
      rows: 5,
      totalRecords: 0,
      paginasPaginador: 0,
      tokenBusqueda: null,
      filtroActivado: false,
      filtroTituloEvento: "",
      filtroArtista: "",
      filtroEditor: "",
    };
    this.datasourceEventos = [];
  }

  //Metodo que solo se ejecuta al montar el componente (Ciclo de vida de React)
  componentDidMount() {
    this.obtenerEventos(
      100,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );
  }

  actualizarPaginador(arrayCodigos, tokenBusqueda, onPage) {
    this.datasourceEventos = this.datasourceEventos.concat(arrayCodigos);
    const paginasPaginador = obtenerPaginasPaginador(
      this.datasourceEventos,
      this.state.rows
    );

    this.setState({
      tokenBusqueda: tokenBusqueda || null,
      paginasPaginador: paginasPaginador,
      totalRecords: this.datasourceEventos.length,
    });
    //this.datasource = response.data.contenido;
    if (onPage === false) {
      this.setState({
        first: 0,
        eventos: this.datasourceEventos.slice(0, this.state.rows),
      });
    }
  }

  obtenerEventos(
    cantidad,
    tokenBusqueda,
    titulo,
    fechaInicioEventoDesde,
    fechaInicioEventoHasta,
    artista,
    editor,
    categoriaProducto,
    tituloProducto,
    onPage
  ) {
    eventoService
      .listarEventos(
        cantidad,
        tokenBusqueda,
        titulo,
        fechaInicioEventoDesde,
        fechaInicioEventoHasta,
        artista,
        editor,
        categoriaProducto,
        tituloProducto
      )
      .then((response) => {
        this.actualizarPaginador(
          response.data.contenido,
          response.data.tokenBusqueda,
          onPage
        );
      });
  }

  filtrarBusquedaEventos(filtroActivado) {
    let filtroTituloEvento, filtroArtista, filtroEditor;

    this.datasourceEventos = [];
    this.setState({ filtroActivado: filtroActivado, tokenBusqueda: null });

    /* Seteo los valores en las variables porque setState no es "instantaneo" y se ejecuta la llamada con filtros... */
    if (!filtroActivado) {
      filtroTituloEvento = null;
      filtroArtista = null;
      filtroEditor = null;

      this.setState({
        filtroTituloEvento: "",
        filtroArtista: "",
        filtroEditor: "",
      });
    } else {
      filtroTituloEvento = this.state.filtroTituloEvento;
      filtroArtista = this.state.filtroArtista;
      filtroEditor = this.state.filtroEditor;
    }
    this.obtenerEventos(
      100,
      null,
      filtroTituloEvento,
      null,
      null,
      filtroArtista,
      filtroEditor,
      null,
      null,
      false
    );
  }

  onPage(event) {
    const startIndex = event.first;
    const endIndex = event.first + this.state.rows;
    this.setState({
      first: startIndex,
      eventos: this.datasourceEventos.slice(startIndex, endIndex),
    });

    if (
      this.state.tokenBusqueda != null &&
      this.state.paginasPaginador - 2 === event.page
    ) {
      let filtroTituloEvento, filtroArtista, filtroEditor;
      if (this.state.filtroActivado) {
        filtroTituloEvento = this.state.filtroTituloEvento;
        filtroArtista = this.state.filtroArtista;
        filtroEditor = this.state.filtroEditor;
      } else {
        filtroTituloEvento = null;
        filtroArtista = null;
        filtroEditor = null;
      }

      this.obtenerEventos(
        100,
        this.state.tokenBusqueda,
        filtroTituloEvento,
        null,
        null,
        filtroArtista,
        filtroEditor,
        null,
        null,
        true
      );
    }
  }

  plantillaFechaRegistro(rowData, column) {
    return <span>{pintarFechaATexto(rowData.fechaInicioRegistro)}</span>;
  }

  plantillaFechaEvento(rowData, column) {
    return <span>{pintarFechaATexto(rowData.fechaInicioEvento)}</span>;
  }

  plantillaFechaLimiteRegistro(rowData, column) {
    return <span> {pintarFechaATexto(rowData.fechaLimiteRegistro)}</span>;
  }

  plantillaUrlEvento(rowData) {
    return (
      <a
        style={{ maxWidth: "120px" }}
        className="d-inline-block text-truncate"
        href={rowData.urlEvento}
        target="_blank"
        rel="noopener noreferrer"
      >
        {rowData.urlEvento}
      </a>
    );
  }

  plantillaTitulo(rowData, column) {
    return (
      <div>
        {rowData.imagenFondo !== null && (
          <img
            src={rowData.imagenFondo}
            alt={rowData.artista}
            onError={(e) => {
              e.target.src =
                "https://www.wimacpc.com/assets/images/no-disponible.png";
            }}
            width="48px"
          />
        )}
        <h5>{rowData.titulo}</h5>
        <h6>{rowData.artista}</h6>
      </div>
    );
  }
  plantillaAcciones(rowData, column) {
    return (
      <div>
        <Link to={"/" + rowData.idEvento}>
          <Button
            type="button"
            icon="pi pi-search"
            className="p-button-success mt-2 mt-md-2 mt-lg-0"
            style={{ marginRight: ".5em" }}
          ></Button>
        </Link>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning mt-2 mt-md-2 mt-lg-0"
          style={{ marginRight: ".5em" }}
        ></Button>
        <Link to={"/evento/" + rowData.idEvento + "/codigos"}>
          <Button
            type="button"
            icon="fa fa-barcode"
            className="p-button-warning mt-2 mt-md-2 mt-lg-0 "
            style={{ marginRight: ".5em" }}
          ></Button>
        </Link>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <ToolbarPersonalizado
          titulo={t("TITULO.LISTA_EVENTOS")}
          mostrarBotonRetrocederPagina={true}
        />
        <div className="container">
          <Panel
            header={t("GENERICO.FILTROS")}
            style={{ marginTop: "2em" }}
            toggleable={true}
          >
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 mt-md-2 mt-lg-2 mb-2 mb-md-2 mb-lg-2">
                <span className="p-float-label">
                  <InputText
                    id="titulo_producto"
                    className="form-control"
                    title={t("PRODUCTO.TITULO")}
                    type="text"
                    name="tituloProducto"
                    value={this.state.filtroTituloEvento}
                    onChange={(e) =>
                      this.setState({ filtroTituloEvento: e.target.value })
                    }
                    autoComplete="off"
                  ></InputText>
                  <label htmlFor="titulo_producto">
                    {t("PRODUCTO.TITULO")}
                  </label>
                </span>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 mt-md-2 mt-lg-2 mb-2 mb-md-2 mb-lg-2">
                <span className="p-float-label">
                  <InputText
                    id="filtro_editor"
                    className="form-control"
                    title={t("GENERICO.EDITOR")}
                    type="text"
                    name="tituloProducto"
                    value={this.state.filtroEditor}
                    onChange={(e) =>
                      this.setState({ filtroEditor: e.target.value })
                    }
                    autoComplete="off"
                    required={true}
                  ></InputText>
                  <label htmlFor="filtro_editor">{t("GENERICO.EDITOR")}</label>
                </span>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 mt-md-2 mt-lg-2 mb-2 mb-md-2 mb-lg-2">
                <span className="p-float-label">
                  <InputText
                    id="artista"
                    className="form-control"
                    title={t("GENERICO.ARTISTA")}
                    type="text"
                    name="tituloProducto"
                    value={this.state.filtroArtista}
                    onChange={(e) =>
                      this.setState({ filtroArtista: e.target.value })
                    }
                    autoComplete="off"
                    required={true}
                  ></InputText>
                  <label htmlFor="artista">{t("GENERICO.ARTISTA")}</label>
                </span>
              </div>
            </div>

            <div className="mt-4 d-flex justify-content-center">
              <div className="col-5 col-sm-3 col-md-3 col-lg-2">
                <Button
                  label={t("GENERICO.FILTRAR")}
                  icon="pi pi-filter"
                  iconPos="right"
                  onClick={() => {
                    this.filtrarBusquedaEventos(true);
                  }}
                  className=""
                />
              </div>
              <div className="col-5 col-sm-3 col-md-3 col-lg-2">
                <Button
                  label={t("GENERICO.LIMPIAR")}
                  icon="pi pi-trash"
                  iconPos="right"
                  className=""
                  onClick={() => this.filtrarBusquedaEventos(false)}
                />
              </div>
            </div>
          </Panel>

          <DataTable
            className="mt-4"
            responsive={true}
            value={this.state.eventos}
            lazy={true}
            paginator={true}
            paginatorTemplate="PrevPageLink NextPageLink"
            rows={this.state.rows}
            first={this.state.first}
            totalRecords={this.state.totalRecords}
            onPage={(e) => this.onPage(e)}
          >
            <Column
              field="idEvento"
              style={{ textAlign: "center" }}
              header="Id"
            />

            <Column
              field="titulo"
              /*               body={this.plantillaTitulo}
               */ style={{ textAlign: "center" }}
              header="Titulo"
            />

            <Column
              field="artista"
              /*               body={this.plantillaTitulo}
               */ style={{ textAlign: "center" }}
              header="Artista"
            />

            <Column
              body={this.plantillaUrlEvento}
              header="Url evento"
              style={{ textAlign: "center" }}
            />

            <Column
              body={this.plantillaFechaEvento}
              style={{ textAlign: "center" }}
              header="Fecha evento"
            />

            {/* <Column field="titulo" header="Titulo" />
            <Column field="artista" header="Artista" /> */}
            <Column
              body={this.plantillaAcciones}
              style={{ textAlign: "center" }}
              header="Acciones"
            />
          </DataTable>
          {/*  <embed
            type="text/html"
            src="https://www.youtube.com/embed/8WkuChVeL0s"
            width="500"
            height="200"
          /> */}
        </div>
      </>
    );
  }
}
export default withTranslation()(EventoLista);

EventoLista.propTypes = {
  onChangeEvento: PropTypes.func,
  onChangeCodigo: PropTypes.func,
  esPrevisualizacion: PropTypes.bool,
};
