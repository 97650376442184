import React from "react";
import { Link } from "react-router-dom";
import { Card } from "primereact/card";
import PropTypes from "prop-types";

/* Componente personalizado para mostrar información con una direccion a el. Tiene parametros obligatorios comprobar abajo */
export default function TarjetaPersonalizada(props) {
  const FOOTER = (
    <span>
      <Link to={props.enlace}>
        <button type="button" className="btn btn-primary">
          Acceder
        </button>
      </Link>
    </span>
  );
  return (
    <Card
      title={props.titulo}
      style={props.estilos}
      footer={FOOTER}
      header={
        props.imgCabecera ? (
          <img
            className="Card"
            alt="img"
            style={{ width: "100%", height: "160px" }}
            src={props.imgCabecera}
          />
        ) : null
      }
      className="ui-card-shadow"
    >
      <div style={{ width: "100%", height: "80px" }}>{props.contenido}</div>
    </Card>
  );
}

TarjetaPersonalizada.defaultProps = {
  estilos: { width: "350px", margin: "15px" },
};

TarjetaPersonalizada.propTypes = {
  titulo: PropTypes.string.isRequired,
  enlace: PropTypes.string.isRequired,
  contenido: PropTypes.string.isRequired,
  imgCabecera: PropTypes.string,
  estilos: PropTypes.object,
};
