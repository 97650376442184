import React from "react";
import PropTypes from "prop-types";
import { Calendar } from "primereact/calendar";
import { useTranslation } from "react-i18next";

const es = {
  firstDayOfWeek: 1,
  dayNames: [
    "domingo",
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
  ],
  dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
  dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
  monthNames: [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ],
  monthNamesShort: [
    "ene",
    "feb",
    "mar",
    "abr",
    "may",
    "jun",
    "jul",
    "ago",
    "sep",
    "oct",
    "nov",
    "dic",
  ],
  today: "Hoy",
  clear: "Limpiar",
};

export default function CalendarioPersonalizado(props) {
  //Referencia al calendario y poder acceder a los metodos del componente. Es parecido al @ViewChild de angular
  const calendario = React.createRef();
  const { t } = useTranslation();

  function ocultarCalendario() {
    calendario.current.hideOverlay();
  }

  function limpiarCalendario() {
    calendario.current.onClearButtonClick();
    ocultarCalendario();
  }

  const PIE = () => (
    <div className="p-datepicker-buttonbar">
      <button
        onClick={ocultarCalendario}
        type="button"
        className="p-button p-component p-button-secondary p-button-text-only"
      >
        <span className="p-button-text p-c">{t("GENERICO.ACEPTAR")}</span>
      </button>
      <button
        onClick={limpiarCalendario}
        type="button"
        className="p-button p-component p-button-secondary p-button-text-only"
      >
        <span className="p-button-text p-c">{t("GENERICO.LIMPIAR")}</span>
      </button>
    </div>
  );

  return (
    <Calendar
      ref={calendario}
      locale={props.idiomaCalendario === "es" ? es : null}
      dateFormat="dd/mm/yy"
      showIcon={true}
      value={props.value}
      showTime={props.mostrarHora}
      onChange={(e) => props.cambioValor(e)}
      footerTemplate={PIE}
      required={props.required}
      appendTo={document.body}
      name={props.name}
    />
  );
}

CalendarioPersonalizado.defaultProps = {
  idiomaCalendario: "es",
  mostrarHora: true,
  mostrarIcono: true,
  required: false,
};

CalendarioPersonalizado.propTypes = {
  cambioValor: PropTypes.func.isRequired,
  idiomaCalendario: PropTypes.string,
  mostrarHora: PropTypes.bool,
  mostrarIcono: PropTypes.bool,
  required: PropTypes.bool,
};
