import React from "react";
import InputMask from "react-input-mask";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
//Servicios
import eventoService from "../../../services/eventoService/evento-service";
import codigoService from "../../../services/codigoService/codigo-service";
//Funciones mv
import { cambiarTituloDeLaPagina } from "../../../utils/funciones/funciones";
import PropTypes from "prop-types";

class CodigoValidar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resultadoCodigo: {},
      existeEvento: false,
      codigo: props.match.params.idCodigo || "",
      idEventoComprobado: null,
    };

    this.recuperarEvento = this.recuperarEvento.bind(this);
  }

  //Metodo que solo se ejecuta al montar el componente (Ciclo de vida de React)
  componentDidMount() {
    //Si recibimos por la url 4 Caracteres comprobamos el evento
    if (this.state.codigo.length === 4) {
      this.recuperarEvento(this.state.codigo);
      //Si obtenemos un codigo entero, comprobamos el codigo
    } else if (this.state.codigo.length === 14) {
      this.comprobarCodigo(this.state.codigo);
    }
  }

  async recuperarEvento(idEvento) {
    //Compruebo que sean 4 digitos
    let expresion_regular = new RegExp("^[0-9]{4}$");
    //Compruebo que cumpla la expresión regular y que si no es el mismo idEventoComprobado
    if (
      expresion_regular.test(idEvento) &&
      idEvento !== this.state.idEventoComprobado
    ) {
      await eventoService
        .recuperarEvento(idEvento)
        .then((resp) => {
          this.props.onChangeEvento(resp.data);

          this.setState({ existeEvento: true });
          cambiarTituloDeLaPagina(resp.data.titulo);
        })
        .catch((error) => {
          this.props.onChangeEvento({});
          this.setState({ existeEvento: false });
          cambiarTituloDeLaPagina();
        });

      this.setState({ idEventoComprobado: idEvento });
    }
  }

  async comprobarCodigo(codigo) {
    codigo = codigo.toUpperCase();
    let idEvento = codigo.split("-", 1)[0];
    await this.recuperarEvento(idEvento);

    let expresion_regular = new RegExp(
      "^[0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}$"
    );
    if (expresion_regular.test(codigo)) {
      codigoService
        .recuperarCodigoEstado(codigo)
        .then((resp) => {
          this.props.onChangeCodigo(resp.data);
        })
        .catch((error) => {
          console.log(error);
          this.props.onChangeCodigo({
            idCodigo: codigo,
            estado: error.response.data.Error.codigoError,
          });
        });
    }
  }

  render() {
    return (
      <div>
        <div className="row justify-content-center">
          {this.state.existeEvento === true ? (
            <>
              <div className="col-xs-4 col-sm-11 col-md-10 col-lg-4 pb-3 mt-4">
                <InputMask
                  mask="9999"
                  defaultValue={this.state.idEventoComprobado}
                  className="form-control"
                  style={{
                    textAlign: "center",
                    padding: "25px",
                    fontSize: "23px",
                    textTransform: "uppercase",
                  }}
                  placeholder={this.props.t("CODIGO.INTRODUCE")}
                  onChange={(e) => this.comprobarCodigo(e.target.value)}
                  title={this.props.t("CODIGO.INTRODUCE")}
                  disabled={true}
                ></InputMask>
              </div>

              <div className="col-xs-6 col-sm-11 col-md-10 col-lg-5 pb-3 mt-4">
                <InputMask
                  mask="****-****"
                  className="form-control"
                  style={{
                    textAlign: "center",
                    padding: "25px",
                    fontSize: "23px",
                    textTransform: "uppercase",
                  }}
                  placeholder={this.props.t("CODIGO.INTRODUCE")}
                  onChange={(e) =>
                    this.comprobarCodigo(
                      this.state.idEventoComprobado + "-" + e.target.value
                    )
                  }
                  title={this.props.t("CODIGO.INTRODUCE")}
                ></InputMask>
              </div>
            </>
          ) : (
            <div className="col-xs-12 col-sm-11 col-md-10 col-lg-10 pb-3 mt-4">
              <InputMask
                mask="9999"
                defaultValue={this.state.codigo}
                className="form-control"
                style={{
                  textAlign: "center",
                  padding: "25px",
                  fontSize: "23px",
                  textTransform: "uppercase",
                }}
                placeholder={this.props.t("CODIGO.INTRODUCE")}
                onChange={(e) => this.comprobarCodigo(e.target.value)}
                title={this.props.t("CODIGO.INTRODUCE")}
              ></InputMask>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default compose(withTranslation(), withRouter)(CodigoValidar);

CodigoValidar.propTypes = {
  onChangeEvento: PropTypes.func,
  onChangeCodigo: PropTypes.func,
  esPrevisualizacion: PropTypes.bool,
};
