import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
//Estilos bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Configuracion i18n
import "./components/i18n/i18n";
//Estilos prime
import "primeicons/primeicons.css";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "./assets/font-awesome/css/font-awesome.min.css";
import Amplify from "aws-amplify";
import awsmobile from "./utils/aws/aws-exports";
Amplify.configure(awsmobile);
ReactDOM.render(
  /* Warning primeReact findDOMNode was passed an instance of InputText which is inside StrictMode (Warnings primeReact Components)
    gente con el mismo problema https://stackoverflow.com/questions/60868969/warning-finddomnode-is-deprecated-in-strictmode-when-using-react-bootstrap-navb
    los desarrolladores de componentes están solucionando los problemas https://github.com/react-bootstrap/react-bootstrap/issues/3518   
  <React.StrictMode>
    <App />
  </React.StrictMode>,
*/
  <App />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
