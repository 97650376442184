import moment from "moment";
import "moment/locale/es";
import { Auth } from "aws-amplify";

//Idioma español para mostrar las fechas
export function fechaStringISOAFecha(fechaString) {
  var b = fechaString.split(/\D+/);
  return new Date(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]);
}

export function pintarFechaATexto(fecha) {
  if (fecha instanceof Date) {
    return transformaPrimeraLetraEnMayuscula(
      moment(fecha).locale("es").format("LLLL")
    );
  }

  if (typeof fecha === "string") {
    return transformaPrimeraLetraEnMayuscula(
      moment(new Date(fecha)).locale("es").format("LLLL")
    );
  }
}

export function cambiarTituloDeLaPagina(titulo) {
  if (titulo === undefined) {
    document.title = "Mv_AguaDulce";
  } else {
    document.title = titulo;
  }
}

export function cambiarUrlDeLaPagina(url) {
  if (url === undefined) {
    window.history.pushState(null, "", "/");
  } else {
    window.history.pushState(null, "", url);
  }
}

//Transforma GTM calendar primeng a hora en UTC
export function transformaFechaGtmAUtc(fecha) {
  let anio, mes, dia, hora, minutos, segundos;
  let fechaDevuelta;
  if (fecha instanceof Date) {
    anio = fecha.getFullYear();
    mes = fecha.getMonth();
    dia = fecha.getDate();
    hora = fecha.getHours();
    minutos = fecha.getMinutes();
    segundos = fecha.getSeconds();
    fechaDevuelta = new Date(Date.UTC(anio, mes, dia, hora, minutos, segundos));
  }

  return fechaDevuelta;
}

export function transformaImagenFileEnBase64(file) {
  var base64;
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    base64 = reader.result;
  };
  return base64;
}

function transformaPrimeraLetraEnMayuscula(cadenaTexto) {
  return cadenaTexto.charAt(0).toUpperCase() + cadenaTexto.slice(1);
}

export function invertRGB(rgb) {
  //rgb = Array.prototype.join.call(arguments).match(/(-?[0-9\\.]+)/g);
  let arrayLetras = ["r", "g", "b"];
  for (var i = 0; i < arrayLetras.length; i++) {
    rgb[arrayLetras[i]] = (i === 3 ? 1 : 255) - rgb[arrayLetras[i]];
  }
  return rgb;
}
export async function getAverageRGB(imgEl) {
  var blockSize = 5, // only visit every 5 pixels
    defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
    canvas = document.createElement("canvas"),
    context = canvas.getContext && canvas.getContext("2d"),
    data,
    width,
    height,
    i = -4,
    length,
    rgb = { r: 0, g: 0, b: 0 },
    count = 0;

  if (!context) {
    return defaultRGB;
  }

  height = canvas.height =
    imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
  width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

  context.drawImage(imgEl, 0, 0);

  try {
    data = context.getImageData(0, 0, width, height);
  } catch (e) {
    /* security error, img on diff domain */
    return defaultRGB;
  }

  length = data.data.length;

  while ((i += blockSize * 4) < length) {
    ++count;
    rgb.r += data.data[i];
    rgb.g += data.data[i + 1];
    rgb.b += data.data[i + 2];
  }

  // ~~ used to floor values
  rgb.r = ~~(rgb.r / count);
  rgb.g = ~~(rgb.g / count);
  rgb.b = ~~(rgb.b / count);

  return rgb;
}

export function transformarBase64AImagen(base64) {
  let image = new Image();
  image.setAttribute("crossOrigin", "");
  image.src = base64;
  return image;
}

export function existeImagen(url) {
  let resultado;
  var img = new Image();
  img.onload = () => {
    return true;
  };
  img.onerror = () => {
    return false;
  };
  img.src = url;
  return resultado;
}

export function obtenerPaginasPaginador(array, numeroElementosLista) {
  let paginas = 0;
  let tamanioContenido = array.length;
  while (tamanioContenido > 0) {
    tamanioContenido = tamanioContenido - numeroElementosLista;

    paginas++;
  }
  return paginas;
}

export function hexARgbA(hex) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    );
  }
}

export function guardarEnLocalStorage(clave, valor) {
  localStorage.setItem(clave, valor);
}

export function eliminarClaveLocalStorage(clave) {
  localStorage.removeItem(clave);
}

export function recuperarValorLocalStorage(clave) {
  return localStorage.getItem(clave);
}

export function eliminarTodosLosElementosLocalStorage() {
  localStorage.clear();
}

export function guardarTokenIdYRefreshToken(cognitoUser) {
  guardarEnLocalStorage(
    "tokenAuthorization",
    "Bearer " + cognitoUser.signInUserSession.idToken.jwtToken
  );
  guardarEnLocalStorage(
    "refreshAuthorization",
    cognitoUser.signInUserSession.refreshToken.token
  );
}

export async function refrescarCognitoTokenIdYRefreshToken() {
  try {
    const resultado = await Auth.currentSession();
    const tokenGuardado = recuperarValorLocalStorage("tokenAuthorization");
    if (tokenGuardado !== resultado.idToken.jwtToken) {
      guardarEnLocalStorage(
        "tokenAuthorization",
        "Bearer " + resultado.idToken.jwtToken
      );
    }
    return "Bearer " + resultado.idToken.jwtToken;
  } catch (e) {
    console.log(e);
  }
}

export async function autenticacionCognito(email, password) {
  try {
    const resultado = await Auth.signIn(email, password);
    if (resultado.challengeName === "NEW_PASSWORD_REQUIRED") {
      await Auth.completeNewPassword(resultado, password);
    }
    guardarTokenIdYRefreshToken(resultado);
  } catch (e) {
    throw new Error("Error al autenticar")
  }
}

export async function restablecerContraseniaCognito(email, codigo, password) {
  let resultado = true;
  try {
    await Auth.forgotPasswordSubmit(email, codigo, password);
  } catch (e) {
    resultado = false;
    throw new Error("Error al cambiar password")
  }

  return resultado;
}

export async function enviarCodigoVerificacionCognito(email) {
  let resultado = true;
  try {
    await Auth.forgotPassword(email);
  } catch (e) {
    resultado = false;
    throw new Error("Error al enviar correo")
  }

  return resultado;
}

/**
 * Compara la primera fecha si es mayor que la segunda
 * @param {Date} fecha1
 * @param {Date} fecha2
 * @returns boolean
 */
export function compararFechas(fecha1, fecha2) {
  let resultado = false;
  if (fecha1 instanceof Date && fecha2 instanceof Date) {
    if (fecha1.getTime() > fecha2.getTime()) {
      resultado = true;
    }
  }
  return resultado;
}

/**
 * Ordena array de objetos pasandole el atributo por el que queremos ordernar
 * @param {Array} array
 * @param {String} atributo
 *
 */
export function ordenarArrayPersonalizadoConObjetos(array, atributo) {
  array.sort(function (a, b) {
    if (a[atributo] > b[atributo]) {
      return 1;
    }
    if (a[atributo] < b[atributo]) {
      return -1;
    }
    // son iguales
    return 0;
  });
}

/**
 * Prepara la cantidad de stripe que está a centimos en euros
 * @param {String} cantidad
 *
 * @returns number
 */
export function transformarCantidadStripe(cantidad) {
  let dinero = parseFloat(cantidad);
  return dinero / 100;
}

/**
 * Transforma el texto en el simbolo equivalente (eur -> €)
 * @param {String} tipo
 *
 * @returns String
 */
export function transformarTipoMonedaASimboloMoneda(tipo) {
  let simbolo;
  switch (tipo.toUpperCase()) {
    case "EUR":
      simbolo = "€";
      break;

    case "USD":
      simbolo = "$";
      break;

    case "GBP":
      simbolo = "£";
      break;

    default:
      simbolo = "Error";
      break;
  }

  return simbolo;
}
