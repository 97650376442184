import React from "react";
import { useLocation } from "react-router-dom";
import SidebarPersonalizado from "../sidebarPersonalizado/Sidebar-personalizado";
import "./cacebera.css";
import AcercaDe from "../acercaDe/Acerca-de";
import { useTranslation } from "react-i18next";
import logoEmpresa from "../../assets/img/logo-movired.svg";

export default function Cabecera(props) {
  //Obtenemos el hook para traducir
  const { t } = useTranslation();
  //Obtenemos la propiedad location de props
  const { pathname } = useLocation();
  //Comprobamos la ruta para ocultar la cabecera si es necesario.
  if (
    pathname.match("/login") ||
    pathname.match("/404") ||
    pathname.match(
      "^\\/$|^\\/([0-9]{4})$|^\\/([0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4})$"
    )
  ) {
    return <></>;
  }
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primaryP">
      <div>
        <SidebarPersonalizado />
        <span
          className="ml-2"
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.movired.com/"
        >
          Logo
        </span>
      </div>

      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav"></div>
      </div>

      <ul className="navbar-nav flex-row mr-lg-0">
        <li className="nav-item">
          <a
            href="/"
            style={{ lineHeight: "20px" }}
            className="nav-link disabled text-white mr-lg-0 mr-3"
          >
            usuario
          </a>
        </li>

        <li className="nav-item">
          <div className="nav-link mr-lg-0 mr-3">
            <AcercaDe
              tituloCabecera={t("ACERCA_DE.TITULO")}
              nombreProyecto="Mv_Aguadulce"
              logoUrl={logoEmpresa}
              compania="Movired 2000 S.L"
              web="www.movired.com"
              version="0.1.0"
              privacidad={t("ACERCA_DE.PRIVACIDAD")}
            />
          </div>
        </li>
        <li className="nav-item">
          <a className="nav-link " href="/">
            <i className="fa fa-power-off fa-lg"></i>
          </a>
        </li>
      </ul>
    </nav>
  );
}
