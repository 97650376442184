import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import PropTypes from "prop-types";
import "./acerca-de.css";

export default function AcercaDe(props) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <i
        className="cursor-pointer fa fa-info-circle fa-lg"
        title={props.tituloCabecera}
        aria-hidden="true"
        onClick={() => setVisible(true)}
      ></i>

      <Dialog
        header={props.tituloCabecera}
        visible={visible}
        style={{ width: "450px", minWidth: "100px", header: "10px !important" }}
        onHide={() => setVisible(false)}
        blockScroll={false}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 col-sm-7 col-md-6 col-lg-6 mt-3">
              <h4 className="noMargins">{props.nombreProyecto}</h4>
              <span className="noMargins version">v {props.version}</span>
            </div>
            <div className="col-6 col-sm-7 col-md-6 col-lg-6 mb-4">
              <img
                style={{ float: "right", width: "90%" }}
                alt="logo"
                src={props.logoUrl}
              />
            </div>
          </div>
          <div className="modal-footer col-sm-12 centrar">
            <span>{props.privacidad}</span>
          </div>

          <div className="modal-footerCustom">
            <div className="row customFooter">
              <div className="col-sm-6 col-6 text-left">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"http://" + props.web}
                >
                  {props.web}
                </a>
              </div>
              <div className="col-sm-6 col-6 text-right">
                <span>{props.compania}</span>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

AcercaDe.propType = {
  tituloCabecera: PropTypes.string.isRequired,
  nombreProyecto: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  compania: PropTypes.string.isRequired,
  web: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  privacidad: PropTypes.string.isRequired,
};
