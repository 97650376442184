import axios from "axios";

export default class ImagenService {
  /**
   * Servicio con la llamada (urlApi/codigo/:codigo/estado)
   *
   * @param {string} codigo
   * @returns axios
   */
  static guardarImagen(imgBase64) {
    return axios.post(
      process.env.REACT_APP_API_ADMIN_URL + "imagenes",
      imgBase64
    );
  }
}
