import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InputNumber } from "primereact/inputnumber";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";
import EventoService from "../../../services/eventoService/evento-service";
import { Dialog } from "primereact/dialog";
import GrowlPesonalizado from "../../../components/growlPersonalizado/Growl-pesonalizado";

export default function CodigoNuevo(props) {
  const { t } = useTranslation();
  const { idEvento } = useParams();
  const [cantidadCodigosACrear, setCantidadCodigosACrear] = useState(1);
  const [mostrarDialog, setMostrarDialog] = useState(false);
  const growlPersonalizado = React.useRef();

  function crearCodigos() {
    EventoService.crearCodigos(idEvento, cantidadCodigosACrear)
      .then((success) => {
        growlPersonalizado.current.mostrarMensajeCorrecto(
          t("MENSAJES.CODIGO_GENERAR_OK")
        );
      })
      .catch((error) => {
        growlPersonalizado.current.mostrarMensajeError(
          t("MENSAJE.CODIGO_GENERAR_ERROR")
        );
      });

    setCantidadCodigosACrear(1);
  }

  const pieConfirmDialog = () => {
    return (
      <div>
        <Button
          label={t("GENERICO.SI")}
          icon="pi pi-check"
          onClick={() => {
            setMostrarDialog(false);
            crearCodigos();
          }}
        />
        <Button
          label={t("GENERICO.NO")}
          icon="pi pi-times"
          onClick={() => setMostrarDialog(false)}
          className="p-button-secondary"
        />
      </div>
    );
  };

  return (
    <div className="container">
      <GrowlPesonalizado ref={growlPersonalizado} />

      <InputNumber
        value={cantidadCodigosACrear}
        onChange={(e) => setCantidadCodigosACrear(e.value)}
        min={1}
        max={500}
        mode="decimal"
        showButtons
        className="mt-2 mt-md-2 mt-lg-2 mb-2 mb-md-2 mb-lg-2"
      ></InputNumber>
      <Button
        label={t("GENERICO.AÑADIR")}
        icon="pi pi-plus"
        iconPos="right"
        className="ml-4 mt-2 mt-md-2 mt-lg-2 mb-2 mb-md-2 mb-lg-2"
        onClick={() => setMostrarDialog(true)}
        disabled={cantidadCodigosACrear <= 0 ? true : false}
      />
      <Dialog
        onHide={() => setMostrarDialog(false)}
        visible={mostrarDialog}
        position="center"
        header={t("MENSAJES.TITULO.CONFIRMAR")}
        footer={pieConfirmDialog()}
      >
        {t("MENSAJES.CODIGO_GENERAR_CONFIRMACION", {
          cantidad: cantidadCodigosACrear,
        })}
      </Dialog>
    </div>
  );
}
